import React, { Component, useEffect, useState } from "react";

import moment from "moment";

import "./styles.css";

import { cepMask, cellMask } from "./../../utils/mask";

import Header from "../../components/Header";

import api from "../../services/api";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { FaArrowLeft, FaCheck, FaTimes } from "react-icons/fa";

import { toast } from "react-toastify";

class ScreenCreateUser extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    // const numeroTelLocalStorage = JSON.parse( window.localStorage.getItem('@user_data'))

    // console.log( numeroTelLocalStorage.phoneNumber )

    let user = localStorage.getItem("@user_data");

    if (user) {
      user = JSON.parse(user);
    }

    let empresa = localStorage.getItem("@comer-aqui/empresa");

    empresa = JSON.parse(empresa);

    // 0 || null mostra os 2
    // 1 só entrega
    // 2 só retirar

    let showVouBuscar = false;
    let showEntregarAqui = false;
    let vouBuscarOuEntregarAqui = "ENTREGAR_AQUI";

    if (!empresa.tpFormatrabalho || empresa.tpFormatrabalho == 0) {
      showVouBuscar = true;
      showEntregarAqui = true;
    }

    if (empresa.tpFormatrabalho == 1) {
      showEntregarAqui = true;
      showVouBuscar = false;
    }

    let inputEnderecoDesabilitado = false;

    if (empresa.tpUtilizacep == 2 || empresa.tpUtilizacep == 3) {
      inputEnderecoDesabilitado = true;
    }

    if (empresa.tpFormatrabalho == 2) {
      showEntregarAqui = false;
      showVouBuscar = true;
      vouBuscarOuEntregarAqui = "VOU_BUSCAR";
    }

    this.state = {
      id: "",
      phoneNumber: user && user.phoneNumber ? user.phoneNumber : "",
      phoneNumberStmp: "",
      name: "",
      address: "",
      numberAddress: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      cep: "",
      showForm: true,
      vouBuscarOuEntregarAqui,
      showVouBuscar,
      showEntregarAqui,
      empresa,
      modalAgendamento: false,
      data: "",
      hora: "",
      agendamento: false,
      cepInvalido: false,
      inputEnderecoDesabilitado,
      items: props.location.state.items,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyUp({ target: { type: "tel" } });
  }

  handleInput(event) {
    let { name, value } = event.target;

    if (String(name) === "phoneNumber") {
      value = cellMask(value);
    }

    if (String(name) === "cepMask") {
      value = cepMask(value);
    }

    this.refs[name].value = value;
  }

  async handleKeyUp(event) {
    let {
      target: { type },
    } = event;

    let { empresa, phoneNumberStmp } = this.state;

    let phoneNumber = this.refs.phoneNumber
      ? this.refs.phoneNumber.value
      : this.state.phoneNumber;

    if (type === "tel") {
      if (phoneNumberStmp != phoneNumber) {
        this.setState({ phoneNumberStmp: phoneNumber });
        try {
          const response = await api.get(
            `/cliente?cod_empresa=${
              empresa.codEmpresa
            }&telefone=${phoneNumber.replace(/\D/g, "")}`
          );
          // console.log("resposta", response.data)

          let {
            bairro,
            cep,
            codPessoa,
            complemento,
            endereco,
            nomePessoa,
            nrLogradouro,
            telefone,
            vltaxaEntrega,
            resposta,
          } = response.data;

          if (resposta) {
            if (resposta == 404 || resposta == "404") {
              return;
            }
          }

          if (codPessoa) {
            if (vltaxaEntrega) {
              if (parseFloat(vltaxaEntrega) > 0) {
                empresa.vlTaxaentrega = vltaxaEntrega;
                localStorage.setItem(
                  "@comer-aqui/empresa",
                  JSON.stringify(empresa)
                );
              } else {
                let vltaxaEntregaStorage = localStorage.getItem(
                  "@comer-aqui/vltaxaEntrega"
                );
                empresa.vlTaxaentrega = vltaxaEntregaStorage;
                localStorage.setItem(
                  "@comer-aqui/empresa",
                  JSON.stringify(empresa)
                );
              }
            } else {
              let vltaxaEntregaStorage = localStorage.getItem(
                "@comer-aqui/vltaxaEntrega"
              );
              empresa.vlTaxaentrega = vltaxaEntregaStorage;
              localStorage.setItem(
                "@comer-aqui/empresa",
                JSON.stringify(empresa)
              );
            }

            this.setState({
              id: codPessoa,
              name: nomePessoa,
              address: endereco,
              numberAddress: nrLogradouro,
              complement: complemento,
              neighborhood: bairro,
              cep,
              phoneNumber: cellMask(telefone),
              // city: nomeCidade,
              // state: ufCidade
            });

            this.refs.name.value = nomePessoa;
            this.refs.address.value = endereco;
            this.refs.numberAddress.value = nrLogradouro;
            this.refs.complement.value = complemento;
            this.refs.neighborhood.value = bairro;
            this.refs.cep.value = cepMask(cep);
            this.refs.cep.click();
            this.refs.phoneNumber.value = cellMask(telefone);
          }
        } catch (error) {
          // console.log(error);
        }
      }
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let { empresa, cepInvalido } = this.state;

    if (cepInvalido) {
      if (this.refs.cep && this.refs.cep.value != "") {
        toast.error("Informe um CEP válido");
        return;
      }
    }

    // Cesar - 15/07/2022
    if (
      empresa.tpUtilizacep &&
      (empresa.tpUtilizacep == 2 || empresa.tpUtilizacep == 3)
    ) {
      if (this.state.vouBuscarOuEntregarAqui == "ENTREGAR_AQUI") {
        if (!this.refs.cep) {
          toast.error("Informe o CEP");
          return;
        }

        if (this.refs.cep && this.refs.cep.value === "") {
          toast.error("Informe o CEP");
          return;
        }
      } else {
      }
    }

    if (
      this.state.vouBuscarOuEntregarAqui != "VOU_BUSCAR" &&
      this.state.vouBuscarOuEntregarAqui != "ENTREGAR_AQUI"
    ) {
      toast.error("Informe VOU BUSCAR ou ENTREGAR AQUI!");
      return;
    }

    if (this.refs.phoneNumber.value.length < 13) {
      toast.error("Por favor informe um telefone válido!");
      return;
    }

    if (this.refs.name.value === "") {
      toast.error("Nome não informado!");
      return;
    }

    if (this.state.vouBuscarOuEntregarAqui == "ENTREGAR_AQUI") {
      if (this.refs.address.value === "") {
        toast.error("Endereço não informado!");
        return;
      }

      if (this.refs.numberAddress.value === "") {
        toast.error("Número não informado!");
        return;
      }

      if (this.refs.neighborhood.value === "") {
        toast.error("Bairro não informado!");
        return;
      }
    }

    if (this.state.vouBuscarOuEntregarAqui == "ENTREGAR_AQUI") {
      this.props.cart.buscarOuEntregarAqui = "ENTREGAR_AQUI";
    } else {
      this.props.cart.buscarOuEntregarAqui = "VOU_BUSCAR";
    }

    this.state.phoneNumber = this.refs.phoneNumber.value;
    this.state.name = this.refs.name.value;
    this.state.address = this.refs.address ? this.refs.address.value : "";
    this.state.numberAddress = this.refs.numberAddress
      ? this.refs.numberAddress.value
      : "";
    this.state.complement = this.refs.complement
      ? this.refs.complement.value
      : ""; // Gabriel 28/02/2022 - Estava faltando complemento
    this.state.neighborhood = this.refs.neighborhood
      ? this.refs.neighborhood.value
      : "";

    this.props.addClient(this.state);

    localStorage.removeItem("@user_data");
    localStorage.setItem("@user_data", JSON.stringify(this.state));

    let { data, hora } = this.state;

    if (empresa.tpUtilizaagendamento) {
      if (empresa.tpUtilizaagendamento == 1) {
        toast.error("Agendamento obrigatório!");
        return;
      }
    }

    let agendamento = {
      agendamento: this.state.agendamento,
      data: data ? data : "",
      hora: hora ? hora : "",
    };

    this.props.history.push({
      pathname: "/checkout",
      agendamento,
      items: this.state.items,
      buscarOuEntregarAqui: this.state.vouBuscarOuEntregarAqui,
    });
  };

  async handleSearchCEP(event) {
    try {
      let { value } = event.target;

      value = cepMask(value);

      this.setState({ cep: value });
      this.refs.cep.value = value;

      let { empresa } = this.state;

      if (value) {
        if (String(value).length == 9) {
          const response = await api.get(
            `/cep?cod_empresa=${empresa.codEmpresa}&cep=${value}&tp_utilizacep=${empresa.tpUtilizacep}`
          );

          // console.log("resposta", response.data)

          let {
            cepBairroIni,
            dsBairro,
            dsLogradouro,
            vltaxaEntrega,
            resposta,
          } = response.data;

          this.setState({ cepInvalido: false });

          if (resposta) {
            if (resposta == 404 || resposta == "404") {
              return;
            }
          }

          if (resposta) {
            if (resposta == 500 || resposta == "500") {
              this.setState({ cepInvalido: true });
              toast.error("Esse endereço não é atendido pela empresa.");
            }
          }

          if (empresa.tpUtilizacep === 3 && parseFloat(vltaxaEntrega) === 0) {
            this.setState({ cepInvalido: true });
            toast.error("Esse endereço não é atendido pela empresa.");
          }

          // console.log(response.data)

          if (vltaxaEntrega) {
            if (parseFloat(vltaxaEntrega) > 0) {
              empresa.vlTaxaentrega = vltaxaEntrega;
              localStorage.setItem(
                "@comer-aqui/empresa",
                JSON.stringify(empresa)
              );
            } else {
              let vltaxaEntregaStorage = localStorage.getItem(
                "@comer-aqui/vltaxaEntrega"
              );
              empresa.vlTaxaentrega = vltaxaEntregaStorage;
              localStorage.setItem(
                "@comer-aqui/empresa",
                JSON.stringify(empresa)
              );
            }
          } else {
            let vltaxaEntregaStorage = localStorage.getItem(
              "@comer-aqui/vltaxaEntrega"
            );
            empresa.vlTaxaentrega = vltaxaEntregaStorage;
            localStorage.setItem(
              "@comer-aqui/empresa",
              JSON.stringify(empresa)
            );
          }

          if (cepBairroIni || dsBairro || dsLogradouro) {
            this.setState({
              // volta aqui
              address: dsLogradouro,
              neighborhood: dsBairro,
            });

            if (dsLogradouro && dsBairro) {
              this.setState({ inputEnderecoDesabilitado: true });
            } else {
              this.setState({ inputEnderecoDesabilitado: false });
            }

            // § Essa forma resolve o problade de Endereço 87230000

            if (dsLogradouro === " ") {
              this.refs.address.value = "";
            }

            if (dsLogradouro !== " ") {
              this.refs.address.value = dsLogradouro;
            }
            this.refs.neighborhood.value = dsBairro;
          }
        }
      }
    } catch (error) {
      // console.log(error);
    }
  }

  handleInputVouBuscar = () => {
    this.setState({ showForm: false, vouBuscarOuEntregarAqui: "VOU_BUSCAR" });

    // let { items } = this.props.cart;
    let { items } = this.state;

    let totalDesconto = 0;
    for (let item of items) {
      if (item.product.discountTakeout > 0) {
        item.product.value -= item.product.discountTakeout;
        item.total = item.product.value * item.product.quantity;
        item.product.total = item.total;
        totalDesconto += item.product.discountTakeout;
        this.props.cart.subtotal -=
          item.product.discountTakeout * item.product.quantity;
        this.props.cart.totalprice -=
          item.product.discountTakeout * item.product.quantity;
      }
    }

    if (totalDesconto > 0) {
      toast.info(
        "Você ganhou um desconto de R$ " + totalDesconto + " na retirada!"
      );
    }
  };

  handleInputEntregarAqui = () => {
    this.setState({ showForm: true, vouBuscarOuEntregarAqui: "ENTREGAR_AQUI" });

    // let { items } = this.props.cart;
    let { items } = this.state;

    for (let item of items) {
      if (item.product.discountTakeout > 0) {
        item.product.value += item.product.discountTakeout;
        item.total = item.product.value * item.product.quantity;
        item.product.total = item.total;
        this.props.cart.subtotal +=
          item.product.discountTakeout * item.product.quantity;
        this.props.cart.totalprice +=
          item.product.discountTakeout * item.product.quantity;
      }
    }
  };

  showModalAgendamento = () => {
    this.setState({ modalAgendamento: true }, () => {});
  };

  cancelarAgendamento = () => {
    this.setState(
      {
        modalAgendamento: false,
        data: "",
        hora: "",
        agendamento: false,
      },
      () => {}
    );
  };

  salvarAgendamento = () => {
    let { data, hora } = this.refs;

    if (!data.value) {
      toast.error("Informe a data!");
      return;
    }

    if (!hora.value) {
      toast.error("Informe o horário!");
      return;
    }

    if (
      parseInt(moment(data.value).format("DDMMYYYY")) <
      parseInt(moment().format("DDMMYYYY"))
    ) {
      toast.error("Informe a data maior ou igual a data atual!");
      return;
    }

    if (
      parseInt(moment(data.value).format("DDMMYYYY")) ==
      parseInt(moment().format("DDMMYYYY"))
    ) {
      if (
        parseInt(
          String(hora.value).replace(":", "").replace(":", "").replace(":", "")
        ) < parseInt(moment().format("HHmm"))
      ) {
        toast.error("Informe o horário maior ou igual o horário atual!");
        return;
      }
    }

    this.setState(
      {
        modalAgendamento: false,
        agendamento: true,
      },
      () => {}
    );
  };

  inputData = (event) => {
    this.refs.data.value = event.target.value;
    this.setState({ data: event.target.value }, () => {});
  };

  inputHora = (event) => {
    this.refs.hora.value = event.target.value;
    this.setState({ hora: event.target.value }, () => {});
  };

  render() {
    const {
      showForm,
      modalAgendamento,
      empresa,
      showVouBuscar,
      showEntregarAqui,
      cepInvalido,
      inputEnderecoDesabilitado,
    } = this.state;
    return (
      <>
        <Header />
        <div className="container mt-2 tela-usuario">
          <div>
            <div className="form-row">
              {modalAgendamento && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.8)", // Fundo escuro com transparência
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      padding: "20px",
                      borderRadius: "8px",
                      width: "80%",
                      maxWidth: "500px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <div className="row p-2">
                      <div className="col-md-12 col-xl-12 col-sm-12 col-12">
                        <label>DATA</label>
                        <input
                          type="date"
                          onChange={this.inputData}
                          ref="data"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-12 col-xl-12 col-sm-12 col-12">
                        <label style={{ marginTop: 20 }}>HORÁRIO</label>
                        <input
                          type="time"
                          onChange={this.inputHora}
                          ref="hora"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: 20,
                      }}
                    >
                      <button
                        type="button"
                        onClick={() => this.cancelarAgendamento()}
                        style={{
                          borderRadius: 5,
                          border: "none",
                          color: "#fff",
                          backgroundColor: "#de2339",
                          padding: "10px 15px 10px 15px",
                          fontWeight: 500,
                        }}
                      >
                        CANCELAR
                      </button>
                      <button
                        style={{
                          borderRadius: 5,
                          border: "none",
                          color: "#fff",
                          backgroundColor: "#32a87b",
                          padding: "10px 15px 10px 15px",
                          fontWeight: 500,
                        }}
                        type="button"
                        onClick={() => this.salvarAgendamento()}
                      >
                        SALVAR
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0 mt-1 mb-1 text-center entregar-buscar-pedido">
                {showVouBuscar && (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="forma_do_pedido"
                      id="vou_buscar"
                      value="VOU_BUSCAR"
                      checked={
                        this.state.vouBuscarOuEntregarAqui == "VOU_BUSCAR"
                      }
                      onChange={this.handleInputVouBuscar}
                    />
                    <label className="form-check-label" htmlFor="vou_buscar">
                      Vou buscar
                    </label>
                  </div>
                )}
                {showEntregarAqui && (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="forma_do_pedido"
                      id="entregar_aqui"
                      value="ENTREGAR_AQUI"
                      checked={
                        this.state.vouBuscarOuEntregarAqui == "ENTREGAR_AQUI"
                      }
                      onChange={this.handleInputEntregarAqui}
                    />
                    <label className="form-check-label" htmlFor="entregar_aqui">
                      Entregar aqui
                    </label>
                  </div>
                )}
              </div>

              {(empresa.tpUtilizaagendamento == 1 ||
                empresa.tpUtilizaagendamento == 2) && (
                <div className="col-md-12 col-xl-12 col-sm-12 col-12 p-0 mt-1 mb-1 text-center">
                  <button
                    className={`btn btn-${this.state.agendamento ? "success" : "danger"}`}
                    style={{ borderRadius: 5, margin: "20px 0 20px 0" }}
                    onClick={() => this.showModalAgendamento()}
                  >
                    {this.state.agendamento ?  `AGENDADO ${this.state.data} ${this.state.hora}` : `AGENDAR`}
                  </button>
     

                </div>
              )}

              
              <div className="inputBase">
                <input
                  type="tel"
                  ref="phoneNumber"
                  name="phoneNumber"
                  placeholder="Telefone"
                  onKeyUp={this.handleInput}
                  onBlur={this.handleKeyUp}
                  className="inputInterno"
                />
              </div>
              <div className="inputBase">
                <input
                  onKeyUp={this.handleInput}
                  type="text"
                  ref="name"
                  name="name"
                  placeholder="Nome"
                  className="inputInterno"
                  autoComplete="off"
                />
              </div>
              {showForm && empresa.tpUtilizacep != 0 && (
                <div className="inputBase">
                  <input
                    onKeyUp={(e) => {
                      this.handleInput(e);
                      this.handleSearchCEP(e);
                    }}
                    onClick={(e) => {
                      this.handleSearchCEP(e);
                    }}
                    type="text"
                    ref="cep"
                    name="cep"
                    placeholder="CEP"
                    className="inputInterno"
                    autoComplete="off"
                  />
                </div>
              )}
              {showForm && (
                <div className="inputEndereco">
                  <input
                    onKeyUp={this.handleInput}
                    type="text"
                    ref="address"
                    name="address"
                    disabled={inputEnderecoDesabilitado}
                    placeholder="Endereço"
                    className="inputInterno"
                    autoComplete="off"
                  />
                </div>
              )}
              {showForm && (
                <div className="inputNumero">
                  <input
                    onKeyUp={this.handleInput}
                    type="text"
                    ref="numberAddress"
                    name="numberAddress"
                    placeholder="N°"
                    className="inputInterno"
                    autoComplete="off"
                  />
                </div>
              )}
              {showForm && (
                <div className="inputBase">
                  <input
                    onKeyUp={this.handleInput}
                    type="text"
                    ref="complement"
                    name="complement"
                    placeholder="Complemento"
                    className="inputInterno"
                    autoComplete="off"
                  />
                </div>
              )}
              {showForm && (
                <div className="inputBase">
                  <input
                    onKeyUp={this.handleInput}
                    type="text"
                    ref="neighborhood"
                    name="neighborhood"
                    disabled={inputEnderecoDesabilitado}
                    placeholder="Bairro"
                    className="inputInterno"
                    autoComplete="off"
                  />
                </div>
              )}
            </div>
          </div>

              <div style={{marginTop: 100}}/>

          <div className="fixed-bottom" style={{backgroundColor: "#fff"}}>
            <div
              style={{
                height: 1,
                backgroundColor: "#f1f1f1",
                margin: "0 20px 0 20px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                margin: 20,
              }}
            >
              <button
                className="btn btn-block btn-danger btn-go-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Voltar 
              </button>
              <button
                className="btn btn-block btn-warning"
                onClick={this.handleSubmit}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ cart: state.cart, client: state });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCreateUser);
