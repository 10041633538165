import React, { Component } from "react";

import "./styles.css";

import Header from "../../components/Header";

import ItemComplement from "../../components/ItemComplement";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as ProductCreator } from "../../store/ducks/product";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import iconComerAqui from "../../assets/image/icon.png";

import Currency from "../../utils/currency";
import { HeaderProduto } from "../../components/HeaderProduto";

class ScreenRemoveComplement extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let note = [];

    if (props.location.note) {
      note = props.location.note;
    }

    this.state = {
      checkedItems: [],
      remove: [],
      activeButton: false,
      note,
      adicionais: props.location.adicionais,
      telaAnterior: props.location.telaAnterior,
    };

    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked(e, price = 0, id) {
    const item = e.target.name;
    const isChecked = e.target.checked;

    this.setState((prevState) => {
      const checkedItems = [...prevState.checkedItems];

      if (isChecked) {
        // Adiciona o item marcado
        checkedItems.push({ id, name: item, value: price });
      } else {
        // Remove o item desmarcado
        const updatedCheckedItems = checkedItems.filter(
          (item) => item.id !== id
        );
        checkedItems.length = 0; // Limpa o array original
        checkedItems.push(...updatedCheckedItems); // Copia de volta os itens filtrados
      }

      return { checkedItems };
    });
  }

  async componentDidMount() {
    this.setState({
      remove: this.props.product.product.ingredients,
      activeButton: true,
    });
  }

  handleBack = () => {
    // let { note, adicionais, telaAnterior } = this.state;

    // this.props.history.push({
    //     pathname: `/${telaAnterior}`,
    //     note,
    //     adicionais,
    //     telaAnterior: "remove"
    // });

    // this.props.history.goBack();

    if (localStorage.getItem("@comer-aqui/typeOrder") == "ENTREGA") {
      //   this.props.history.push({ pathname: '/' })
      this.props.history.push(
        "/" + localStorage.getItem("@comer-aqui/empresaUrl")
      );
    } else {
      this.props.history.push(
        "/" +
          localStorage.getItem("@comer-aqui/empresaUrl") +
          "/?id=" +
          localStorage.getItem("@comer-aqui/authorization")
      );
    }
  };

  render() {
    const _handleSubmit = () => {
      const { note, adicionais } = this.state;
      const { grupoObservacao, inComplemento } =
        this.props.product.product.attrs;

      // console.log("lanche add = ", this.state)

      this.props.setRemoveComplement(this.att);

      this.setState({ remove: this.state.checkedItems });

      if (grupoObservacao && grupoObservacao.length > 0) {
        this.props.history.push({
          pathname: "/product-note",
          remove: this.state.checkedItems,
          grupoObservacao,
        });
        return;
      } else {
        if (adicionais && adicionais.length > 0 && inComplemento === "S") {
          this.props.history.push({
            pathname: "/add",
            note,
            adicionais,
            remove: this.state.checkedItems,
            telaAnterior: "remove",
          });
          return;
        } else {
          this.props.history.push({
            pathname: "/product",
            note,
            remove: this.state.checkedItems,
            telaAnterior: "remove",
          });
          return;
        }
      }
    };

    return (
      <div style={{backgroundColor: '#fff', height: "100vh", padding: "20px 0 0 0 ", margin: 0}}>
        <HeaderProduto
          img={this.props.product.product.url}
          alt={this.props.product.product.alt}
          nome={this.props.product.product.name}
          preco={Currency.formatterValue.format(this.props.product.product.vlPreco || this.props.product.product.promotion_price )}
          descricao={this.props.product.product.description}
        />

        

      <div style={{margin: "40px 0 20px 0"}}> 

      <h5 style={{color: "#d80000", fontWeight: 500, marginLeft: 20}}>Retirar ingrediente?</h5>

      </div>

        <div>
          <ul className="list-group mb-5">
            {/* {console.log( "remover ", this.state.checkedItems)} */}
            {this.state.remove !== undefined && this.state.remove.length > 0 ? (
              this.state.remove.map((item) => (
                <ItemComplement
                  key={item.id}
                  id={item.id}
                  name={item.name}
                  onChecked={this.handleChecked}
                />
              ))
            ) : (
              <span className="title-complement-not-found">
                {" "}
                Nenhum ingrediente encontrado :({" "}
              </span>
            )}
          </ul>
        </div>

      
      <div style={{marginTop: 200}}/>


        <div className="fixed-bottom" style={{backgroundColor: "#fff"}}>
          <div style={{height: 1, backgroundColor: "#f1f1f1",  margin: "0 20px 0 20px"}} />

          <div style={{display: "flex", flexDirection: 'column', gap: 10, padding: 20}}>
              <div >
                <button
                  className="btn btn-danger btn-block btn-go-back"
                  onClick={this.handleBack}
                >
                   Voltar
                </button>
              </div>
              <div >
                <button
                  className="btn btn-primary btn-block"
                  disabled={!this.state.activeButton}
                  onClick={() => {
                    _handleSubmit();
                  }}
                >
                 Avançar 

                </button>
              </div>
            </div>
          </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductCreator, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenRemoveComplement);
