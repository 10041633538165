const Types = {
    NEW_CART: 'cart/NEW_CART',
    ADD_ITEM: 'cart/ADD_ITEM',
    REMOVE_ITEM: 'cart/REMOVE_ITEM',
    EDIT_ITEM: 'cart/EDIT_ITEM',
    CHECKOUT: 'cart/CHECKOUT',
    ADD_CLIENT: 'cart/ADD_CLIENT'
}

const INITIAL_STATE = {
    id: null,
    createdat: null,
    dstype: '',
    typeorder: null,
    scheduled: false,
    numberorder: '',
    client: {
        id: 1,
        name: '',
        phone: '',
        address: {
            city: '',
            state: '',
            neighborhood: '',
            streetname: '',
            streetnumber: '',
            postalcode: '',
            complement: ''
        }
    },
    items: [
        // {
        // id: '',
        // sequence: 0,
        // description : '',
        // quantity: 0,
        // price: 0.0,
        // totalPrice: 0.0,
        // add: [],
        // remove: [],
        // observation: '',
        // status: 0 }
    ],
    subtotal: 0.0,
    totalprice: 0.0,
    discount: 0.0,
    addition: 0.0
    //geolocation
}

let SEQUENCE_ORDER = 1;

export default function cart(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.NEW_CART:
            var typeorder = 0;

            if (String(action.payload.typeorder) === "MESA") {
                typeorder = 1;
            } else if (String(action.payload.typeorder) === "ENTREGA") {
                typeorder = 4;
            }

            return { ...state, items: [], id: SEQUENCE_ORDER++, createdat: new Date(), dstype: action.payload.typeorder, subtotal: 0, totalprice: 0, numberorder: action.payload.numberorder, typeorder }
        case Types.ADD_ITEM:
            const items = [...state.items];

            let totals = 0;

            items.push(action.payload.items);

            items.map(item => {
                totals += item.product.total;
                item.ordered = state.id;
            })
            return { ...state, items, subtotal: totals, totalprice: totals }
        case Types.REMOVE_ITEM:
            const items2 = state.items.filter(item => item.id !== action.payload.id);
            let totals2 = 0;

            items2.map(item => (
                totals2 += item.product.total
            ))

            state.items = items2; //Gabriel - 16/04/2022
            
            return { ...state, items: items2, subtotal: totals2, totalprice: totals2 }  
        case Types.EDIT_ITEM:
            return state

        case Types.CHECKOUT:
            return INITIAL_STATE;

        case Types.ADD_CLIENT:
            const client = { ...state.client }
            const address = { ...client.address }

            client.id = action.payload.client.id;
            client.name = action.payload.client.name;
            client.phone = action.payload.client.phoneNumber;

            address.city = action.payload.client.city;
            address.state = action.payload.client.state;
            address.streetname = action.payload.client.address;
            address.streetnumber = action.payload.client.numberAddress;
            address.postalcode = action.payload.client.cep;
            address.complement = action.payload.client.complement;
            address.neighborhood = action.payload.client.neighborhood;

            client.address = address;

            return { ...state, client };
        default:
            return state;
    }
}

export const Creators = {
    newCart: (typeorder, numberorder, items) => ({
        type: Types.NEW_CART,
        payload: {
            typeorder,
            numberorder,
            items
        }
    }),

    addItem: (items) => ({
        type: Types.ADD_ITEM,
        payload: {
            items
        }
    }),

    removeItem: (id) => ({
        type: Types.REMOVE_ITEM,
        payload: {
            id
        }
    }),

    checkOut: () => ({
        type: Types.CHECKOUT
    }),

    addClient: (client) => ({
        type: Types.ADD_CLIENT,
        payload: {
            client
        }
    })
}
