import React, { Component } from "react";

import "./styles.css";

import Header from "../../components/Header";

import ItemComplement from "../../components/ItemComplement";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as ProductCreator } from "../../store/ducks/product";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { toast } from "react-toastify";

import Currency from "../../utils/currency";

import iconComerAqui from "../../assets/image/icon.png";
import { HeaderProduto } from "../../components/HeaderProduto";

class ScreenAddComplement extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let note = [];

    if (props.location.note) {
      note = props.location.note;
    }

    let { qtMaximoadicional, qtMinimoadicional } =
      this.props.product.product.attrs;

    this.state = {
      checkedItems: [],
      add: props.location.adicionais,
      remove: props.location.remove,
      activeButton: false,
      telaAnterior: props.location.telaAnterior,
      note,
      qtMaximoadicional,
      qtMinimoadicional,
      qtAdicional: 0,
    };

    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked(e, price = 0, vlPreco = 0, id) {
    const item = e.target.name;
    const isChecked = e.target.checked;

    const checkedItems = [...this.state.checkedItems];

    let add = {
      id,
      name: item,
      value: price,
    };

    let { qtAdicional } = this.state;

    if (isChecked) {
      checkedItems.push(add);
      this.setState({ qtAdicional: qtAdicional + 1 }, () => {});
    } else {
      checkedItems.splice(checkedItems.indexOf(add), 1);
      this.setState({ qtAdicional: qtAdicional - 1 }, () => {});
    }

    this.setState({ checkedItems }, () => {});
  }

  async componentDidMount() {
    this.setState({ activeButton: true });

    this.setState(
      {
        innerHeight: window.innerHeight * 0.01,
      },
      () => {
        document.documentElement.style.setProperty(
          "--vh",
          `${this.state.innerHeight * 100}px`
        );
      }
    );

    window.addEventListener("resize", () => {
      this.setState(
        {
          innerHeight: window.innerHeight * 0.01,
        },
        () => {
          document.documentElement.style.setProperty(
            "--vh",
            `${this.state.innerHeight * 100}px`
          );
        }
      );
    });
  }

  handleBack = () => {
    let { note, adicionais, telaAnterior } = this.state;

    // this.props.history.push({
    //     pathname: `/${telaAnterior}`,
    //     note,
    //     adicionais,
    //     telaAnterior: "add"
    // });

    this.props.history.goBack();

    // if (localStorage.getItem('typeorder') == 4) {
    //     this.props.history.push({ pathname: '/' })
    // } else {
    //     this.props.history.push( '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'));

    // }
  };

  handleSubmit = () => {
    const {
      note,
      add,
      remove,
      qtAdicional,
      qtMaximoadicional,
      qtMinimoadicional,
    } = this.state;

    if (qtMaximoadicional) {
      if (qtAdicional > qtMaximoadicional) {
        toast.error(
          `Você só pode selecionar até ${qtMaximoadicional} ingrediente(s)`
        );
        return;
      }
    }

    if (qtMinimoadicional) {
      if (qtAdicional < qtMinimoadicional) {
        toast.error(
          `Você deve selecionar ao menos ${qtMinimoadicional} ingrediente(s)`
        );
        return;
      }
    }

    this.props.setAddComplement(this.state.checkedItems);

    this.props.history.push({
      pathname: "/product",
      note,
      adicionais: add,
      remove,
      telaAnterior: "add",
    });
  };

  render() {
    let { add, qtMaximoadicional, qtMinimoadicional } = this.state;

    // console.log(add)

    return (
      <>
        <HeaderProduto
          img={this.props.product.product.url}
          alt={this.props.product.product.alt}
          nome={this.props.product.product.name}
          preco={Currency.formatterValue.format(
            this.props.product.product.vlPreco ||
              this.props.product.product.promotion_price
          )}
          descricao={this.props.product.product.description}
        />

        <div>
          <span style={{ fontSize: 20, marginLeft: 10, color: "#3477e3", fontWeight: 600 }}>
            Adicionar ingrediente?
          </span>
          {parseInt(qtMinimoadicional) > 0 &&
          parseInt(qtMaximoadicional) > 0 ? (
            <p className="info-qtadicional">
              Escolha entre {qtMinimoadicional} e {qtMaximoadicional} opções
            </p>
          ) : (
            ""
          )}
          {parseInt(qtMinimoadicional) == 0 &&
          parseInt(qtMaximoadicional) > 0 ? (
            <p className="info-qtadicional">
              Escolha entre 0 e {qtMaximoadicional} opções
            </p>
          ) : (
            ""
          )}
          {parseInt(qtMinimoadicional) > 0 &&
          parseInt(qtMaximoadicional) == 0 ? (
            <p className="info-qtadicional">
              Escolha ao menos {qtMinimoadicional} opções
            </p>
          ) : (
            ""
          )}
        </div>

        <div >
          <ul className="list-group mb-5">
            {add !== undefined && add.length > 0 ? (
              add.map((item) => (
                <ItemComplement
                  key={item.codProduto}
                  id={item.codProduto}
                  name={item.descricaoProduto}
                  price={item.precoVenda}
                  onChecked={this.handleChecked}
                />
              ))
            ) : (
              <span className="title-complement-not-found">
                {" "}
                Nenhum ingrediente encontrado :({" "}
              </span>
            )}
          </ul>

        </div>
     
     
        <div  style={{marginTop: 200}}/>


<div className="fixed-bottom" 

  style={{backgroundColor: "#fff"}}
>

<div
    style={{
      height: 1,
      backgroundColor: "#f1f1f1",
      margin: "0 20px 0 20px",
    }}
  />

  <div
    style={{
      display: "flex",
      flexDirection: "column",
      gap: 10,
      margin: 20,
    }}
  >
      <button
        className="btn btn-block btn-danger btn-go-back"
        onClick={this.handleBack}
      >
 
         Voltar
      </button>
      <button
        className="btn btn-primary btn-block"
        disabled={!this.state.activeButton}
        onClick={() => {
          this.handleSubmit();
        }}
      >
        Avançar  
      </button>
      </div>

</div>
     
     
      </>
    );
  }
}

const mapsStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductCreator, dispatch);

export default connect(
  mapsStateToProps,
  mapDispatchToProps
)(ScreenAddComplement);
