import React, { Component } from "react";

import "./styles.css";

import Currency from "../../utils/currency";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as ProductCreator } from "../../store/ducks/product";

import { toast } from "react-toastify";

import iconComerAqui from "../../assets/image/icon.png";

class ItemProduct extends Component {
  constructor(props) {
    super(props);

    let empresa = localStorage.getItem("@comer-aqui/empresa");

    this.state = {
      grupo: props.grupo,
      errored: false,
      empresa: empresa ? JSON.parse(empresa) : false,
      dataParams: props.dataParams,
    };
  }

  onError() {
    if (!this.state.errored) {
      this.setState({ errored: true });
    }
  }

  render() {
    const {
      id,
      grupo,
      grupo_id,
      name,
      url,
      description,
      vlPreco,
      price,
      alt,
      index,
      type,
      promotion_price,
      dataParams,
    } = this.props;

    //  console.log("certo",this.props)

    const { errored, empresa } = this.state;

    // console.log("loja = ", empresa)

    const handleSelectedItem = (item) => {
      this.props.setProduct(item);
      this.props.setProducts(this.props.all_products);

      let { empresa } = this.state;

      if (!empresa) {
        return;
      }

      // Cesar - 20/07/2022
      if (dataParams) {
        if (localStorage.getItem("@comer-aqui/typeOrder") === "MESA") {
          if (empresa.tpSituacaoqrcode === 0) {
            toast.error(
              "A mesa esta fechada, não é possível realizar pedidos!"
            );
            return;
          } else {
            if (empresa.situacao === 0) {
              toast.error("Loja fechada, não é possível realizar pedidos!");
              return;
            }
          }
        }
      }

      let {
        grupo: { adicionais, grupoObservacao },
      } = this.state;

      if (!item.attrs.grupoObservacao && item.grupo.idObs > 0) {
        item.attrs.grupoObservacao = grupoObservacao;
      }

      // PRODUTOS QUE NÃO POSSUEM FILHOS
      if (String(item.type) === "N") {
        if (item.attrs.ingredientes && item.attrs.ingredientes.length > 0) {
          item.history.push({
            pathname: "/remove",
            grupoObservacao,
            adicionais,
          });
          return;
        } else if (
          item.attrs.grupoObservacao &&
          item.attrs.grupoObservacao.length > 0
        ) {
          item.history.push({ pathname: "/product-note", grupoObservacao });
          return;
        } else if (
          adicionais &&
          adicionais.length > 0 &&
          item.attrs.inComplemento === "S"
        ) {
          item.history.push({ pathname: "/add", adicionais });
          return;
        } else {
          item.history.push({ pathname: "/product", adicionais });
          return;
        }
      }

      // PIZZA
      if (String(item.type) === "E") {
        item.history.push({ pathname: "/pizza" });
      }

      // PRODUTOS QUE POSSUI FILHOS
      if (String(item.type) === "G") {
        // item.history.push({ pathname: '/remove' });
        item.history.push({ pathname: "/select-products" });
      }
    };

    return (
      <article
        className="row"
        key={`${index}-${id}-${grupo_id}`}
        style={{
          backgroundColor: "#fhfhfh",
          borderRadius: 5,
          marginBottom: 10,
          border: "none",
          margin: 10,
          minHeight: 110,
          overflow: "hidden",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          <div
            // style={{ marginRight: 20 }}
            onClick={() => {
              this.props.showModal(url);
            }}
          >
            {errored || !url ? (
              <img
                src={iconComerAqui}
                alt="Comer Aqui"
                className="img-fluid"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  minWidth: "100px",
                  borderRadius: "8px 0 0 8px",
                  backgroundPosition: "center",
                  opacity: "0.3",
                }}
              />
            ) : (
              <img
                src={url}
                alt={alt}
                onError={this.onError.bind(this)}
                className="img-fluid"
                style={{
                  width: "100%",
                  backgroundColor: "#f1f1f1",
                  objectFit: "cover",
                  minWidth: "100px",
                  height: "100%",
                  borderRadius: "8px 0 0 8px",
                  backgroundPosition: "center",
                }}
              />
            )}
          </div>

          <div
            className="product pl-0 col-9"
            style={{marginLeft: 20 }}
            onClick={() => {
              if (localStorage.getItem("@comer-aqui/typeOrder") === "MESA") {
                empresa.tpSituacaoqrcode === 0
                  ? toast.error(
                      `${empresa.fantasia} está fechada no momento, volte mais tarde!`
                    )
                  : handleSelectedItem(this.props);
              } else {
                empresa.situacao === 0
                  ? toast.error(
                      `${empresa.fantasia} está fechada no momento, volte mais tarde!`
                    )
                  : handleSelectedItem(this.props);
              }
            }}
          >
            <div className="product-name">
              <span style={{ color: "#b80000" }}>{String(name)}</span>
            </div>


            {description && (
              <div className="product-info" style={{paddingRight: 25}}>
                <span style={{ color: "#6d6d6d",}}>{String(description)}</span>
              </div>
            )}


            <div style={{paddingRight: 10}} >
              {dataParams ? (
                <div>
                  {type == "G" && (
                    <span className="a-partir-de"> A partir de: </span>
                  )}
                  {type == "E" && (
                    <span className="a-partir-de"> A partir de:</span>
                  )}
                  <span>{Currency.formatterValue.format(vlPreco)}</span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: 20,
                    padding: 5
                  }}
                >
                  {type == "G" && (
                    <span className="a-partir-de"> A partir de:</span>
                  )}
                  {type == "E" && (
                    <span className="a-partir-de">A partir de:</span>
                  )}
                  {promotion_price > 0 && (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                        justifyContent: "flex-end",
                      }}
                    >
                      <del
                        className="valor-antigo"
                        style={{ color: "#ad0000" }}
                      >
                        {Currency.formatterValue.format(price)}
                      </del>{" "}
                      <p
                        style={{
                          color: "#1eb35a",
                          fontWeight: 600,
                          margin: 0,
                          padding: 0,
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {Currency.formatterValue.format(promotion_price)}
                      </p>
                    </span>
                  )}
                  {promotion_price == 0 ||
                    (promotion_price == null && (
                      <span
                        style={{
                          color: "#000",
                          margin: 0,
                          padding: 0,
                          fontSize: 16,
                        }}
                      >
                        {Currency.formatterValue.format(price)}
                      </span>
                    ))}
                </div>
              )}
            </div>


          </div>
        </div>
      </article>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductCreator, dispatch);

export default connect(null, mapDispatchToProps)(ItemProduct);
