import { QRScaner, Camera } from "react-qrcode-scan";
import React from "react";

export default class QRReader extends React.Component {
  state = {
    results: [],
    camera: null,
    qrcode: null,
  };

  onError = (error) => {
    console.error("Erro na câmera:", error);
    // Lógica de tratamento de erro, se necessário
  };

  onScan = (data) => {
    console.log("Código QR escaneado:", data);
    this.props.onQRScan(data);
  };

  render() {
    const { results, camera, qrcode } = this.state;
    return (
      <Camera onError={this.onError}>
        {(cameras) =>
          cameras.length > 0 ? (
            <QRScaner
              style={{ width: "100%", height: "100%" }}
              onScan={this.onScan}
              camera={cameras[0]}
              onError={this.onError}
            />
          ) : (
            <div> No Camera</div>
          )
        }
      </Camera>
    );
  }
}
