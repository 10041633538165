import React from 'react';
import { Route } from 'react-router-dom';

import { AnimatedSwitch } from 'react-router-transition';

import Home from './pages/ScreenHome/index';
import Products from './pages/ScreenProducts/index';
import Pizza from './pages/ScreenPizza/index';
import PizzaQuantidade from './pages/ScreenPizzaQuantidade';
import Product from './pages/ScreenProduct/index';
import ProductNote from './pages/ScreenProductNote/index';
import SelectProducts from './pages/ScreenSelectProducts/index';
import Cart from './pages/ScreenCart/index';
import RemoveComplement from './pages/ScreenRemoveComplement/index';
import AddComplement from './pages/ScreenAddComplement/index';
import CreateUser from './pages/ScreenCreateUser/index';
import Checkout from './pages/ScreenCheckout/index';
import StatusOrder from './pages/ScreenStatusOrder/index';
import ScreenPizzaBorder from './pages/ScreenPizzaBorder';

// import "./App.css";

import "./styles/animation.css";

import { Switch, useLocation } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

export default function Routes() {
    const location = useLocation();

    return (
        // <TransitionGroup>
        //     <CSSTransition key={location.key} classNames="transition" timeout={200}>
        //         <Switch location={location}>
        <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 1 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
            
        >
            <Route path="/home" component={Home} />
            <Route path="/" exact component={Products} />
            <Route path="/pizza" component={Pizza} />
            <Route path="/pizza-quantity" component={PizzaQuantidade} />
            <Route path="/product" component={Product} />
            <Route path="/product-note" component={ProductNote} />
            <Route path="/select-products" component={SelectProducts} />
            <Route path="/cart" component={Cart} />
            <Route path="/remove" component={RemoveComplement} />
            <Route path="/add" component={AddComplement} />
            <Route path="/user" component={CreateUser} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/status" component={StatusOrder} />
            <Route path="/pizza-border" component={ScreenPizzaBorder} />
            <Route component={Products} />
        </AnimatedSwitch >
        //         </Switch>
        //     </CSSTransition>
        // </TransitionGroup>
    );
}