import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import Currency from "../../utils/currency";

import "./styles.css";

import Header from "../../components/Header";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { FaArrowLeft, FaPlus, FaMinus, FaCheck } from "react-icons/fa";

import { toast } from "react-toastify";

import iconComerAqui from "../../assets/image/icon.png";
import { HeaderProduto } from "../../components/HeaderProduto";

let idSeq = 1;

class ScreenPizza extends Component {
  addPizza = (itemCart) => {
    return {
      id: itemCart.idProduto,
      iditem: this.props.product.product.id,
      name: itemCart.dsProduto,
      quantity: itemCart.quantity,
      value: itemCart.vlPreco,
      total: itemCart.vlTotal,
    };
  };

  addItem = () => {
    const item = {
      id: `PIZZA_${Math.floor(Math.random() * 256)}${idSeq++}`,
      ordered: this.props.cart.id,
      product: {
        id: this.props.product.product.id,
        name: this.props.product.product.name,
        quantity: this.state.quantity,
        value: this.state.total,
        total: this.state.total,
      },
      status: 1,
      remove: undefined,
      add: undefined,
      items: [],
    };

    return item;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    window.scroll(0, 0);

    if (this.props.cart.id == null) {
      const numberOrder = localStorage.getItem("@comer-aqui/numberOrder");
      const typeOrder = localStorage.getItem("@comer-aqui/typeOrder");
      this.props.newCart(typeOrder, numberOrder);
    }

    const { itemsCart, quantity_flavors } = this.state;

    if (itemsCart.length == 0) {
      toast.error("Selecione o(s) sabor(es)");
      return;
    }

    let pizzas = [];

    let quantity = 0;
    let total = 0;

    for (let itemCart of itemsCart) {
      pizzas.push(this.addPizza(itemCart));
      quantity = parseInt(quantity) + parseInt(itemCart.quantity);
      total = parseFloat(total) + parseFloat(itemCart.vlTotal);
    }

    if (quantity != quantity_flavors) {
      toast.error(
        "Selecione o(s) sabor(es) de acordo com a quantidade selecionada"
      );
      return;
    }

    this.setState({ quantity, total });

    const pizza = this.addItem();

    pizza.items = pizzas;

    let pathname = "/pizza-quantity";

    let bordas = this.props.product.products.filter(
      ({ tpProduto }) => tpProduto == "O"
    );

    if (bordas.length > 0) {
      pathname = "/pizza-border";
    }

    // console.log("bordar = ", bordas)

    this.props.history.push({
      pathname,
      pizza,
      bordas,
      quantity_flavors: this.state.quantity_flavors,
      product: this.state.product,
      tpProduto: 0,
    });
  };

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let { attrs } = props.product.product;

    let { products } = props.product;

    let pizzas = [];
    let tmpizza = "";

    // valida pelo tmPizza e idGrupo
    for (let product of products) {
      if (
        product.idProduto !== attrs.idProduto &&
        product.tmPizza == attrs.tmPizza &&
        product.idGrupo == attrs.idGrupo
      ) {
        // console.log(product.tmPizza)
        // console.log('teste',product)
        tmpizza = product.tmPizza;
        pizzas.push(product);
      }
    }

    let candy = [];
    let salty = [];

    // this.setState({tmpizza:pizza.tmPizza} )

    const TP_DOCE = "D";
    const TP_SALGADO = "S";

    for (let pizza of pizzas) {
      pizza.quantity = 0;
      pizza.vlTotal = 0;
      if (String(pizza.tpSabor) === TP_SALGADO) {
        salty.push(pizza);
      }
      if (String(pizza.tpSabor) === TP_DOCE) {
        candy.push(pizza);
      }
    }

    this.state = {
      product: attrs,
      pizzas,
      tmpizza,
      salty,
      candy,
      itemsCart: [],
      quantity: 0,
      total: 0,
      quantity_flavors: 0,
      type_flavor: "SALGADO",
      amount_of_flavors: attrs.qtFracionado,
      errored: [],
    };

    // this.handleCheckboxQuanitityFlovors = this.handleCheckboxQuanitityFlovors.bind(this);
  }

  async handleTypeFlavorSalty() {
    this.setState({ type_flavor: "SALGADO" });
  }

  async handleTypeFlavorCandy() {
    this.setState({ type_flavor: "DOCE" });
  }

  async handleCheckboxQuanitityFlovors(event) {
    let { itemsCart } = this.state;

    let quantity_flavors = event.target.value;

    let quantitys = 0;
    for (let product of itemsCart) {
      quantitys = parseInt(quantitys) + parseInt(product.quantity);
    }

    if (parseInt(quantitys) > parseInt(quantity_flavors)) {
      toast.error(
        "Remova itens do carrinho para poder diminuir a quantidade de sabores"
      );
      return;
    }

    this.setState({ quantity_flavors });
  }

  async handleAddProduct(pizza) {
    let { quantity_flavors, itemsCart } = this.state;

    let quantitys = 0;
    for (let product of itemsCart) {
      quantitys = parseInt(quantitys) + parseInt(product.quantity);
    }

    if (quantity_flavors == 0) {
      window.scroll(0, 0);
      toast.error("Selecione a quantidade de sabores");
      return;
    }

    if (parseInt(quantitys) >= parseInt(quantity_flavors)) {
      toast.error("Já foi selecionada a quantidade máxima de sabor(es)");
      return;
    }

    let idProdutos = itemsCart.map(({ idProduto }) => idProduto);

    if (idProdutos.includes(pizza.idProduto)) {
      for (let product of itemsCart) {
        if (product.idProduto == pizza.idProduto) {
          product.vlTotal =
            parseFloat(product.vlTotal) + parseFloat(pizza.vlPreco);
          product.quantity = parseInt(product.quantity) + 1;
        }
      }
      this.setState({ itemsCart });
    } else {
      pizza.vlTotal = pizza.vlPreco;
      pizza.quantity = 1;

      this.setState({ itemsCart: [...itemsCart, pizza] });
    }
  }

  async handleRemoveProduct(pizza) {
    let { itemsCart } = this.state;

    let quantitys = 0;
    for (let product of itemsCart) {
      if (product.idProduto == pizza.idProduto) {
        quantitys = parseInt(quantitys) + parseInt(product.quantity);
      }
    }

    if (parseInt(quantitys) == 1) {
      pizza.vlTotal = 0;
      pizza.quantity = 0;
      itemsCart.splice(itemsCart.indexOf(pizza), 1);
      this.setState({ itemsCart });
    } else {
      for (let product of itemsCart) {
        if (product.idProduto == pizza.idProduto) {
          product.vlTotal =
            parseFloat(product.vlTotal) - parseFloat(pizza.vlPreco);
          product.quantity = parseInt(product.quantity) - 1;
        }
      }
      this.setState({ itemsCart });
    }
  }

  handleAddedOnCart = (pizza) => {
    let { itemsCart } = this.state;

    let idProdutos = itemsCart.map(({ idProduto }) => idProduto);

    if (idProdutos.includes(pizza.idProduto)) {
      return "content-item-flavors-added";
    } else {
      return "content-item-flavors";
    }
  };

  onError(pizza) {
    if (!this.state.errored.includes(pizza.idProduto)) {
      this.setState({ errored: [...this.state.errored, pizza.idProduto] });
    }
  }

  render() {
    const renderInputAmountOfFlavors = () => {
      let { quantity_flavors, amount_of_flavors } = this.state;

      let rows = [];

      for (let i = 1; i <= amount_of_flavors; i++) {
        rows.push(
          <div key={i} className="form-check form-check-inline">
            <input
              checked={quantity_flavors == i}
              className="form-check-input"
              type="radio"
              name="quantity_flavors"
              id={`id-${i}`}
              value={i}
              onChange={this.handleCheckboxQuanitityFlovors.bind(this)}
            />
            <label className="form-check-label" htmlFor={`id-${i}`}>
              {i}
            </label>
          </div>
        );
      }

      return rows;
    };

    const {
      product: { product },
    } = this.props;

    let { itemsCart, salty, candy, quantity_flavors, type_flavor, errored } =
      this.state;

    return (
      <div style={{ margin: 0, padding: 0, height: "100vh" }}>
        {/* <Header /> */}
        <HeaderProduto
          img={this.props.product.product.url}
          alt={this.props.product.product.alt}
          nome={this.props.product.product.name}
          preco={Currency.formatterValue.format(
            this.props.product.product.vlPreco ||
              this.props.product.product.promotion_price
          )}
          descricao={this.props.product.product.description}
        />

        <div className="container content-pizza">
          <div className="row">
            {/* <div className="col-md-12 col-xl-12 col-sm-12 col-12 text-center text-uppercase mt-2">
                            <h5>{product.name}</h5>
                        </div> */}

            <div
              className="col-md-12 col-xl-12 col-sm-12 col-12 text-center"
              style={{ marginTop: 30 }}
            >
              QUANTIDADE DE SABORES
            </div>

            <div className="col-md-12 col-xl-12 col-sm-12 col-12 d-flex justify-content-center align-items-center">
              {renderInputAmountOfFlavors()}
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12">
              <div
                id="content-button-flavors"
                //   className="col-md-5 col-xl-5 col-sm-5 col-5"
                style={{ padding: 30 }}
              >
                <button
                  className={`btn btn-sm btn-block ${
                    type_flavor == "SALGADO" ? "btn-danger" : "btn-secondary"
                  } button-select-flavor`}
                  onClick={this.handleTypeFlavorSalty.bind(this)}
                >
                  SALGADOS
                </button>
                <button
                  className={`btn btn-sm btn-block ${
                    type_flavor == "DOCE" ? "btn-danger" : "btn-secondary"
                  } button-select-flavor`}
                  onClick={this.handleTypeFlavorCandy.bind(this)}
                >
                  DOCES
                </button>
              </div>
            </div>

            <div className="row">
                <div
                  id="content-items-flavors"
                //   className="col-md-7 col-xl-7 col-sm-7 col-7"
                  style={{margin: "10px 0 10px 30px"}}
                >
                  {itemsCart.length == 0 && (
                    <div className="content-item-flavors">
                      Nenhum produto adicionado :(
                    </div>
                  )}
                  {itemsCart.map((item, index) => (
                    <span
                      className="content-item-flavors"
                      key={`${item.idProduto}-${index}`}
                    >
                      ({item.quantity}/{quantity_flavors}){item.dsProduto}
                    </span>
                  ))}
                </div>
              </div>
          </div>

          
          <div className="content mt-2">
            {type_flavor == "SALGADO" && (
              <div className="row">
                <div  style={{fontSize: 16, fontWeight: 500, color: "#333", backgroundColor: "#f1f1f1", padding: "5px 0px 5px 10px", border: "solid 1px #ddd", flex: 1}}>
                  SALGADOS
                </div>
                {salty.map((pizza) => (
                  <div
                    key={pizza.idProduto}
                    className={`mb-1 col-md-12 col-xl-12 col-sm-12 col-12 pt-1 pb-1 `}
                    style={{borderBottom: "solid 1px #f1f1f1"}}
                  >
                    <div className="row">
                      <div className="content-img-product col-md-2 col-xl-2 col-sm-2 col-2 pt-0 pb-0">
                        {errored.includes(pizza.idProduto) ||
                        !pizza.dsImagem ? (
                          // <FaCamera size="25" />
                          // <img className="img-product" src='http://comeraqui.com.br/IconeVazio.svg' alt='' />
                          <img
                            src={iconComerAqui}
                            alt="Comer Aqui"
                            className="img-fluid"
                            style={{
                              opacity: 0.35,
                              height: 72,
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img
                            className="img-product"
                            src={pizza.dsImagem}
                            alt={pizza.dsProduto}
                            onError={() => this.onError(pizza)}
                          />
                        )}
                      </div>
                      <div className="col-md-8 col-xl-8 col-sm-8 col-8 pt-0 pb-0">
                        <h5>{pizza.dsProduto}</h5>
                        <span>{pizza.dsIngredientes}</span>
                        <br />
                        <span className="product-value">
                          {Currency.formatterValue.format(pizza.vlPreco)}
                        </span>
                      </div>
                      <div className="col-md-2 col-xl-2 col-sm-2 col-2 pt-0 pb-0 d-flex flex-column align-items-center justify-content-center">
                        <div
                          className="btn btn-block btn-sm btn-add-remove-quantity"
                          onClick={() => this.handleAddProduct(pizza)}
                          style={{padding: 5, width: 30, height: 30,  backgroundColor: "#00d97e", borderRadius: 100, color: "#fff", cursor: "pointer"}}
                        >
                          <FaPlus />
                        </div>

                        <h6 style={{margin: "5px 0 5px 0", color: "#666"}}>
                          {pizza.quantity}
                        </h6>


                        <div
                          className="btn btn-block btn-sm btn-add-remove-quantity"
                          onClick={() => this.handleRemoveProduct(pizza)}
                          style={{padding: 5, width: 30, height: 30,  backgroundColor: "#ee4949", borderRadius: 100, color: "#fff", cursor: "pointer"}}

                        >
                          <FaMinus />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {type_flavor == "DOCE" && (
              <div className="row">
                <div  style={{fontSize: 16, fontWeight: 500, color: "#333", backgroundColor: "#f1f1f1", padding: "5px 0px 5px 10px", border: "solid 1px #ddd", flex: 1}}>
                  DOCES
                </div>
                {candy.map((pizza) => (
                  <div
                    key={pizza.idProduto}
                    className={`mb-1 col-md-12 col-xl-12 col-sm-12 col-12 pt-1 pb-1 `}
                  >
                    <div className="row">
                      <div className="content-img-product col-md-2 col-xl-2 col-sm-2 col-2 pt-0 pb-0">
                        {errored.includes(pizza.idProduto) ||
                        !pizza.dsImagem ? (
                          // <FaCamera size="25" />
                          // <img className="img-product" src='http://comeraqui.com.br/IconeVazio.svg' alt='' />
                          <img
                            src={iconComerAqui}
                            alt="Comer Aqui"
                            className="img-fluid"
                            style={{
                              opacity: 0.35,
                              height: 72,
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <img
                            className="img-product"
                            src={pizza.dsImagem}
                            alt={pizza.dsProduto}
                            onError={() => this.onError(pizza)}
                          />
                        )}
                      </div>
                      <div className="col-md-8 col-xl-8 col-sm-8 col-8 pt-0 pb-0">
                        <h5>{pizza.dsProduto}</h5>
                        <span>{pizza.dsIngredientes}</span>
                        <br />
                        <span className="product-value">
                          {Currency.formatterValue.format(pizza.vlPreco)}
                        </span>
                      </div>
                      <div className="col-md-2 col-xl-2 col-sm-2 col-2 pt-0 pb-0 d-flex flex-column align-items-center justify-content-center">
                        <div
                          className="btn btn-block btn-sm btn-add-remove-quantity"
                          onClick={() => this.handleAddProduct(pizza)}
                          style={{padding: 5, width: 30, height: 30,  backgroundColor: "#00d97e", borderRadius: 100, color: "#fff", cursor: "pointer"}}

                        >
                          <FaPlus />
                        </div>
                        <h6 style={{margin: "5px 0 5px 0", color: "#666"}}>
                          {pizza.quantity}
                        </h6>
                        <div
                          className="btn btn-block btn-sm btn-add-remove-quantity"
                          onClick={() => this.handleRemoveProduct(pizza)}
                          style={{padding: 5, width: 30, height: 30,  backgroundColor: "#ee4949", borderRadius: 100, color: "#fff", cursor: "pointer"}}

                        >
                          <FaMinus />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div style={{ marginTop: 100 }} />

          <div className="fixed-bottom" style={{ backgroundColor: "#fff" }}>
            <div
              style={{
                height: 1,
                backgroundColor: "#f1f1f1",
                margin: "0 20px 0 20px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                margin: 20,
              }}
            >
              <button
                className="btn btn-danger btn-block btn-go-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Voltar
              </button>

              <button
                className="btn btn-primary btn-block p-3"
                onClick={this.handleSubmit}
              >
                Avançar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPizza);
