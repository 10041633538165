import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import Currency from "../../utils/currency";

import "./styles.css";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { FaArrowLeft, FaCheck, FaPlus, FaMinus } from "react-icons/fa";

import Header from "../../components/Header";

import { toast } from "react-toastify";

import iconComerAqui from "../../assets/image/icon.png";

import { HeaderProduto } from "../../components/HeaderProduto";

class ScreenProductNote extends Component {
  voltar = () => {
    if (localStorage.getItem("@comer-aqui/typeOrder") == "ENTREGA") {
      //   this.props.history.push({ pathname: '/' })
      this.props.history.push(
        "/" + localStorage.getItem("@comer-aqui/empresaUrl")
      );
    } else {
      // console.log("teste 56 = ", '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'))
      this.props.history.push(
        "/" +
          localStorage.getItem("@comer-aqui/empresaUrl") +
          "/?id=" +
          localStorage.getItem("@comer-aqui/authorization")
      );
    }
  };

  handleSubmit = () => {
    let { grupoObservacao, remove } = this.state;

    for (let observacao of grupoObservacao) {
      let radioChecked = false;
      let totalCheckeds = 0;
      for (let item of observacao.itens) {
        if (observacao.qt_maximo == 1) {
          if (item.checked) {
            radioChecked = true;
          }
        }
        if (item.checked) {
          totalCheckeds++;
        }
      }

      if (observacao.qt_minimo > 0) {
        if (observacao.qt_selecionada < observacao.qt_minimo) {
          if (observacao.qt_maximo == 1) {
            toast.error(`Selecione uma opção de "${observacao.ds_obs}"`);
          } else {
            toast.error(
              `Selecione o mínimo de ${observacao.qt_minimo} item(ns) em "${observacao.ds_obs}"`
            );
          }
          return;
        }
      }

      if (totalCheckeds > 0) {
        observacao.mostrarCarrinho = true;
      } else {
        observacao.mostrarCarrinho = false;
      }
    }

    // if (ingredientes && ingredientes.length > 0) {
    //     this.props.history.push({
    //         pathname: '/remove',
    //         adicionais,
    //         note: grupoObservacao,
    //         telaAnterior: "product-note"
    //     });
    //     return;
    // } else if (adicionais && adicionais.length > 0) {
    //     this.props.history.push({
    //         pathname: '/add',
    //         adicionais,
    //         note: grupoObservacao,
    //         telaAnterior: "product-note"
    //     });
    //     return;
    // } else {
    this.props.history.push({
      pathname: "/product",
      note: grupoObservacao,
      telaAnterior: "product-note",
      remove,
    });
    return;
    // }
  };

  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    if (!props.product.product) {
      this.props.history.push({ pathname: "/" });
      return;
    }

    let {
      product: {
        product: { attrs },
      },
    } = props;

    let grupoObservacao = [];

    if (attrs.grupoObservacao) {
      grupoObservacao = attrs.grupoObservacao;
    }

    let i = 0;
    for (let observacao of grupoObservacao) {
      observacao.qt_selecionada = 0;

      for (let item of observacao.itens) {
        item.id = i++;
        item.checked = false;
        item.quantidade = 0;
        item.valor = 0;
      }

      if (!observacao.dsParentese) {
        let dsParentese = "";

        if (observacao.qt_maximo == 1) {
          if (observacao.qt_minimo == 1) {
            dsParentese = "obrigatório";
          } else {
            dsParentese = "opcional";
          }
        } else {
          if (observacao.qt_gratis > 0) {
            dsParentese = `${dsParentese}${observacao.qt_gratis.toString()} grátis`;
          }

          if (observacao.qt_minimo > 0) {
            if (dsParentese != "") {
              dsParentese = String.prototype.concat(dsParentese, ", ");
            }

            dsParentese = `${dsParentese}mínimo ${observacao.qt_minimo}`;
          }

          if (observacao.qt_maximo > 0) {
            if (dsParentese != "") {
              dsParentese = String.prototype.concat(dsParentese, ", ");
            }

            dsParentese = String.prototype.concat(
              dsParentese,
              "máximo ",
              observacao.qt_maximo.toString()
            );
          }

          if (observacao.maxItem > 0) {
            if (dsParentese != "") {
              dsParentese = String.prototype.concat(dsParentese, ", ");
            }

            dsParentese = String.prototype.concat(
              dsParentese,
              "até ",
              observacao.maxItem.toString(),
              " de cada"
            );
          }
        }

        if (dsParentese != "") {
          dsParentese = String.prototype.concat("(", dsParentese, ")");
        }

        observacao.dsParentese = dsParentese;
      }
    }

    let { ingredientes } = attrs;

    this.state = {
      grupoObservacao,
      ingredientes,
      adicionais: props.location.adicionais,
      telaAnterior: props.location.telaAnterior,
      remove: props.location.remove,
      errored: false,
    };
  }

  onError() {
    if (!this.state.errored) {
      this.setState({ errored: true });
    }
  }

  handleInput = (e, keyObservacao, keyItem) => {
    let { grupoObservacao } = this.state;

    let iObs = 0;
    let iObsItem = 0;
    for (let observacao of grupoObservacao) {
      if (iObs == keyObservacao) {
        for (let observacaoItem of observacao.itens) {
          if (observacao.qt_maximo == 1 && observacao.qt_minimo == 1) {
            //RadioButton
            if (iObsItem == keyItem) {
              //Item clicado
              observacaoItem.checked = true;
              observacaoItem.valor = observacaoItem.vl_item;
              observacao.qt_selecionada = 1;
              observacaoItem.quantidade = 1;
            } else if (iObs == keyObservacao) {
              //Item diferente do clicado
              observacaoItem.checked = false;
              observacaoItem.quantidade = 0;
              observacaoItem.valor = 0;
            }
          } else {
            if (iObsItem == keyItem) {
              if (observacaoItem.checked) {
                observacaoItem.checked = false;
                observacao.qt_selecionada--;
                observacaoItem.quantidade = 0;
                observacaoItem.valor = 0;
              } else if (
                observacao.qt_maximo > observacao.qt_selecionada ||
                observacao.qt_maximo == 0
              ) {
                observacaoItem.checked = true;
                observacao.qt_selecionada++;
                observacaoItem.quantidade = 1;
                if (observacao.qt_selecionada > observacao.qt_gratis) {
                  observacaoItem.valor += observacaoItem.vl_item;
                }
              } else {
                toast.error(
                  "Ops... A quantidade máxima para estes complementos é de "
                    .concat(observacao.qt_maximo)
                    .concat(" item(ns)")
                );
              }
            }
          }

          iObsItem++;
        }
      }
      iObs++;
    }

    for (let observacao of grupoObservacao) {
      if (observacao.qt_gratis >= observacao.qt_selecionada) {
        for (let observacaoItem of observacao.itens) {
          observacaoItem.valor = 0;
        }
      }
    }

    this.setState({ grupoObservacao });
  };

  handleClickQuantity = (item, type) => {
    let { grupoObservacao } = this.state;

    for (let observacao of grupoObservacao) {
      for (let observacaoItem of observacao.itens) {
        if (observacaoItem == item) {
          if (!observacao.qt_selecionada) {
            observacao.qt_selecionada = 0;
          }

          if (type === "add") {
            if (
              observacaoItem.quantidade < observacao.maxItem ||
              observacao.maxItem == 0
            ) {
              if (
                observacao.qt_selecionada < observacao.qt_maximo ||
                observacao.qt_maximo == 0
              ) {
                observacao.qt_selecionada++;
                observacaoItem.quantidade++;

                if (observacao.qt_selecionada > observacao.qt_gratis) {
                  observacaoItem.valor += observacaoItem.vl_item;
                }
              } else {
                toast.error(
                  "A quantidade máxima para estes complementos é de "
                    .concat(observacao.qt_maximo)
                    .concat(" item(ns)")
                );
              }
            } else {
              toast.error(
                "A quantidade máxima por item é ".concat(observacao.maxItem)
              );
            }
          } else if (observacaoItem.quantidade >= 1) {
            observacao.qt_selecionada--;
            observacaoItem.quantidade--;

            if (observacaoItem.valor > 0) {
              observacaoItem.valor -= observacaoItem.vl_item;
            }
          }

          observacaoItem.checked = observacaoItem.quantidade > 0;

          // observacaoItem.valor = observacaoItem.quantidade * observacaoItem.vl_item;
        }
      }
    }

    for (let observacao of grupoObservacao) {
      if (observacao.qt_gratis >= observacao.qt_selecionada) {
        for (let observacaoItem of observacao.itens) {
          observacaoItem.valor = 0;
        }
      }
    }

    this.setState({ grupoObservacao });
  };

  render() {
    const { product } = this.props;

    const { grupoObservacao, errored } = this.state;

    return (
      <div style={{backgroundColor: "#fff", height: "100vh"}}>
        {/* § Ainda tenho que terminar  */}
        <HeaderProduto
          img={this.props.product.product.url}
          alt={this.props.product.product.alt}
          nome={this.props.product.product.name}
          preco={Currency.formatterValue.format(
            this.props.product.product.vlPreco ||
              this.props.product.product.promotion_price
          )}
          descricao={this.props.product.product.description}
        />

        <div className="container p-0">
          <div className="product-note-content" id="portal-root">
            {grupoObservacao.length > 0 &&
              grupoObservacao.map((observacao, keyObservacao) => (
                <div  key={keyObservacao}>

                  <div  key={keyObservacao}>
                    <div className=" d-flex titulo" style={{flex: 1, width: "100%"}}>
                      
                      <h6 className="mr-2">{observacao.ds_obs}</h6>{" "}
                      {observacao.ds_titulo} {observacao.dsParentese}
                    </div>


                    {observacao.itens.map((item, keyItem) => (
                      <div className="col-md-12 input-item" key={keyItem}>
                        {observacao.qt_maximo == 1 &&
                          observacao.qt_minimo == 1 && (
                            <div className="input-item-information">
                              <div
                                className="form-check"
                                style={{ marginBottom: "10px" }}
                              >
                                <input
                                  onClick={(e) =>
                                    this.handleInput(e, keyObservacao, keyItem)
                                  }
                                  checked={item.checked}
                                  className="form-check-input"
                                  onChange={() => this.handleInput()}
                                  type="radio"
                                  name={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                  id={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                >
                                  <span className="ds_item">
                                    {item.ds_item}
                                  </span>
                                  {
                                    // observacao.qt_maximo > 1 && observacao.qt_minimo > 1 &&
                                    item.vl_item > 0 && (
                                      <span className="vl_item">
                                        (
                                        {Currency.formatterValue.format(
                                          item.vl_item
                                        )}
                                        )
                                      </span>
                                    )
                                  }
                                </label>
                              </div>
                              <span className="valor">
                                {Currency.formatterValue.format(item.valor)}
                              </span>
                            </div>
                          )}

                        {/* checkbox */}
                        {(observacao.maxItem == 1 ||
                          observacao.qt_maximo == 1) &&
                          observacao.qt_minimo != 1 && (
                            <div className="input-item-information">
                              <div
                                className="form-check"
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  padding: 10,
                                  borderBottom: "solid 1px #ddd",
                                }}
                                onClick={() =>
                                  this.handleInput(null, keyObservacao, keyItem)
                                }
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    checked={item.checked}
                                    onChange={(e) => e.stopPropagation()} // Evita que o click no checkbox dispare o click na div
                                    className="custom-checkbox"
                                    type="checkbox"
                                    id={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                  />
                                  <label
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                    htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                    onClick={(e) => e.preventDefault()} // Evita que o clique no label tenha comportamento padrão
                                  >
                                    <span className="ds_item">
                                      {item.ds_item}
                                    </span>
                                    {item.vl_item > 0 && (
                                      <span className="vl_item">
                                        (
                                        {Currency.formatterValue.format(
                                          item.vl_item
                                        )}
                                        )
                                      </span>
                                    )}
                                  </label>
                                </div>
                                <span className="valor">
                                  {Currency.formatterValue.format(item.valor)}
                                </span>
                              </div>
                            </div>
                          )}

                        {/* Mais e menos + & - */}
                        {observacao.maxItem != 1 &&
                          observacao.qt_maximo != 1 && (
                            <div className="input-item-information" style={{borderBottom: "solid 1px #f1f1f1"}}>
                              <div className="input-quantidade">

                                <div 
                                
                                onClick={() =>
                                  this.handleClickQuantity(item, "del")
                                }
                                style={{cursor: 'pointer', width: 30, height: 30, backgroundColor: "#ee4949", color: "#fff", borderRadius: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
                                
                                <FaMinus />

                                </div>

                                <h6 className="m-3" style={{color: "#808080"}}>{item.quantidade}</h6>

                                <div
                                     onClick={() =>
                                      this.handleClickQuantity(item, "add")
                                    }
                                
                                style={{cursor: 'pointer', width: 30, height: 30, backgroundColor: "#00d97e", color: "#fff", borderRadius: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
                                  <FaPlus />
                                </div>

                              </div>
                              <div className="form-check">
                                <label
                                  className="form-check-label"
                                  htmlFor={`${keyObservacao}-${keyItem}-${item.id_obs}-${item.id_obsitem}-${item.vl_item}-${item.ds_item}`}
                                >
                                  <span className="ds_item">
                                    {item.ds_item}
                                  </span>
                                  {
                                    // observacao.qt_maximo > 1 && observacao.qt_minimo > 1 &&
                                    (observacao.qt_selecionada >
                                      observacao.qt_gratis ||
                                      observacao.qt_gratis == 0) &&
                                      item.vl_item > 0 && (
                                        <span className="vl_item" style={{color: "#333"}}>
                                          (
                                          {Currency.formatterValue.format(
                                            item.vl_item
                                          )}
                                          )
                                        </span>
                                      )
                                  }
                                </label>
                              </div>
                              <span className="valor">
                                {Currency.formatterValue.format(item.valor)}
                              </span>
                            </div>
                          )}
                      </div>
                    ))}
                    <div className="col-md-12">{/* <hr /> */}</div>
                  </div>
                </div>
              ))}
            <div className="espacamento" />
          </div>

          <div style={{marginTop: 100}}/>

          <div className="fixed-bottom" style={{ backgroundColor: "#fff" }}>
            <div
              style={{
                height: 1,
                backgroundColor: "#f1f1f1",
                margin: "0 20px 0 20px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                margin: 20,
              }}
            >
              <button
                className="btn btn-danger btn-block btn-go-back"
                onClick={() => {
                  this.voltar();
                }}
              >
                Voltar
              </button>

              <button
                className="btn btn-primary btn-block p-3"
                onClick={this.handleSubmit}
              >
                Avançar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenProductNote);
