import React, { Component } from "react";

import "./styles.css";

import { FaInfoCircle } from "react-icons/fa";

import MaisInfoModal from "../MaisInfoModal/index";

import logoBranca from "../../assets/image/logoBranca.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      empresa: false,
      modalVisible: false,
    };
  }

  componentDidMount() {
    let empresa = localStorage.getItem("@comer-aqui/empresa");

    if (empresa) {
      this.setState({ empresa: JSON.parse(empresa) });
    }
  }

  handleShowModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible }, () => {});
  };

  render() {
    let { props } = this;

    let { empresa, modalVisible } = this.state;

    return (
      <header id="main-header" style={{ marginBottom: 30 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>


        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

        {empresa.logo && (
            <div style={{ padding: 20 }}>
              <img
                src={`data:image/jpeg;base64,${empresa.logo}`}
                alt={empresa.fantasia}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 5,
                  width: 80,
                  height: 70,
                  objectFit: "contain",
                }}
              />
            </div>
          )}
          <div>
            <div style={{ fontSize: 20, fontWeight: 600 }}>
              {empresa.fantasia}
            </div>
            {empresa.endereco &&
              empresa.bairro &&
              empresa.nrLogradouro &&
              empresa.cep && (
                <p className="endereco">
                  <span>{empresa.endereco},</span>
                  <span>{empresa.bairro} -</span>
                  <span>{empresa.nrLogradouro},</span>
                  <span>{empresa.cep}</span>
                </p>
              )}
          </div>

        </div>

          <div
            className="mais-info"
            style={{ cursor: "pointer", marginLeft: 30, color: "#666" }}
          >
            <MaisInfoModal
              empresa={empresa}
              show={modalVisible}
              onClickClose={() => this.handleShowModal()}
            />
            <span onClick={() => this.handleShowModal()}>
              <FaInfoCircle className="mr-1 info-icon" />{" "}
              <span className="d-none d-lg-inline-block">Mais info</span>
            </span>
          </div>


        </div>
        {empresa.dsMensageminicial && (
          <div className="col-12 px-0 py-1 dsMensageminicial">
            <h6
              className="titulo mb-0"
              dangerouslySetInnerHTML={{ __html: empresa.dsMensageminicial }}
            />
          </div>
        )}
      </header>
    );
  }
}

export default Header;
