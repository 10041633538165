import React, { Component } from "react";

import "./styles.css";

import Header from "../../components/Header";

import imgLocation from "../../../src/assets/image/location.png";

import imgGerence from "../../../src/assets/image/GerenceLogo.png";

import status from "../../status.mp3";

import api from "../../services/api";
import { BASE_URL } from "../../constants/BASE_URL";
import pattern from "../../assets/patternBorrado.png";
import ComerAquiLogo from "../../assets/image/logo.png";
import GerenceLogo from "../../assets/image/novaLogoGerence.png";

class ScreenStatusOrder extends Component {
  constructor(props) {
    super(props);

    let empresa = localStorage.getItem("@comer-aqui/empresa");


    empresa = JSON.parse(empresa);

    this.state = {
      client: props.location.state.client,
      verifyStatusOrder: true,
      statusOrder: "P",
      motivo: "",
      idpedido: props.location.state.idpedido,
      empresa,
      total: props.location.state.total,
    };
  }

  async componentDidMount() {
    this.setState(
      {
        innerHeight: window.innerHeight * 0.01,
      },
      () => {
        document.documentElement.style.setProperty(
          "--vh",
          `${this.state.innerHeight * 100}px`
        );
      }
    );

    window.addEventListener("resize", () => {
      this.setState(
        {
          innerHeight: window.innerHeight * 0.01,
        },
        () => {
          document.documentElement.style.setProperty(
            "--vh",
            `${this.state.innerHeight * 100}px`
          );
        }
      );
    });

    this.verifyStatusOrder();

    let { empresa } = this.state;

    let { qtSegundosbuscastatus, qtSegundoscancelapedido } = empresa;

    if (!qtSegundosbuscastatus || qtSegundosbuscastatus == 0) {
      qtSegundosbuscastatus = 30000;
    } else {
      qtSegundosbuscastatus = `${qtSegundosbuscastatus}000`;
    }

    if (!qtSegundoscancelapedido || qtSegundoscancelapedido == 0) {
      qtSegundoscancelapedido = 60000 * 3;
    } else {
      qtSegundoscancelapedido = `${qtSegundoscancelapedido}000`;
    }

    setInterval(() => this.verifyStatusOrder(), qtSegundosbuscastatus);

    setTimeout(() => this.cancelStatusOrder(), qtSegundoscancelapedido);
  }

  cancelStatusOrder = async () => {
    try {
      let { idpedido, statusOrder, empresa } = this.state;

      if (statusOrder == "P") {
        let params = {
          idempresa: empresa.codEmpresa,
          idpedido,
          situacao: "C",
          motivo: "Loja não recebeu",
        };

        await api.post(
          `/statuspedido?idempresa=${params.idempresa}&idpedido=${params.idpedido}&situacao=${params.situacao}&motivo=${params.motivo}`
        );

        this.verifyStatusOrder();
      }
    } catch (erro) {
      //
      console.log(erro);
    }
  };

  verifyStatusOrder = async () => {
    let { idpedido, verifyStatusOrder, statusOrder, empresa } = this.state;

    if (verifyStatusOrder) {
      let that = this;

      let baseUrl = BASE_URL;

      fetch(
        `${baseUrl}/statuspedido?idempresa=${empresa.codEmpresa}&idpedido=${idpedido}`
      )
        .then((response) => response.json())
        .then(function handleData(data) {
          let { motivo, situacao } = data;

          if (situacao == "E" || situacao == "C") {
            that.setState({ verifyStatusOrder: false });
          }

          if (situacao != statusOrder) {
            let audio = new Audio(status);
            audio.play();
          }

          that.setState({ statusOrder: situacao, motivo });
        })
        .catch(function handleError(error) {});
    }
  };

  render() {
    let { client, statusOrder, motivo } = this.state;

    return (
      <div
        style={{
          backgroundImage: `url(${pattern})`,
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        {/* <Header /> */}

        <div
          style={{
            padding: 10,
            borderBottom: "solid 1px #ddd",
            backgroundColor: "#fff",
            width: "100%", 
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
         
          }}
        >
          <img src={ComerAquiLogo} />
          <img src={GerenceLogo} style={{width: 150}} />

        </div>

        <main style={{marginTop: "-100px"}}>
          <h2 className="m-0">Status</h2>
          {statusOrder == "P" && <h3 className="m-0">Pedido pendente</h3>}
          {statusOrder == "P" && (
            <span className="status-texto">
              Aguardando a loja receber o seu pedido!
            </span>
          )}
          {statusOrder == "I" && <h3 className="m-0">Pedido recebido</h3>}
          {statusOrder == "I" && (
            <span className="status-texto">
              Não se preocupe a loja já recebeu o seu pedido!
            </span>
          )}
          {statusOrder == "I" && (
            <span className="status-icone" role="img" aria-label="smile">
              {" "}
              <br /> 😃{" "}
            </span>
          )}
          {statusOrder == "E" && (
            <h3 className="text-success m-0">Pedido em entrega</h3>
          )}
          {statusOrder == "E" && (
            <span className="status-texto">
              Oba! seu pedido já está com o entregador!
            </span>
          )}
          {statusOrder == "E" && (
            <span className="status-icone" role="img" aria-label="smile">
              {" "}
              <br /> 😋{" "}
            </span>
          )}
          {statusOrder == "C" && (
            <h3 className="text-danger m-0">Pedido cancelado</h3>
          )}
          {statusOrder == "C" && (
            <span className="status-texto">
              Seu pedido foi cancelado! Motivo: {motivo}
            </span>
          )}
          {statusOrder == "C" && (
            <span className="status-icone" role="img" aria-label="smile">
              {" "}
              <br /> 😥{" "}
            </span>
          )}
          {statusOrder == "R" && (
            <h3 className="text-danger m-0">Pedido recusado</h3>
          )}
          {statusOrder == "R" && (
            <span className="status-texto">
              Por favor refaça seu pedido. Motivo: {motivo}
            </span>
          )}
          {statusOrder == "R" && (
            <span className="status-icone" role="img" aria-label="smile">
              {" "}
              <br /> 😥{" "}
            </span>
          )}
        </main>

        <div />

        <div className="fixed-bottom" style={{ backgroundColor: "#fff" }}>
          <div
            style={{
              height: 1,
              backgroundColor: "#f1f1f1",
              margin: "0 20px 0 20px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              margin: 20,
            }}
          >
            <button
              className="btn btn-block btn-primary p-3"
              onClick={() => {
                this.props.history.push({
                  pathname: `/${ localStorage.getItem("@comer-aqui/empresaUrl")}`,
                });
              }}
              disabled={this.state.disable}
            >
              início
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ScreenStatusOrder;
