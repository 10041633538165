import React, { Component } from "react";

import SweetAlert from "sweetalert-react";

import "sweetalert/dist/sweetalert.css";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { Creators as CartCreator } from "../../store/ducks/cart";

import Currency from "../../utils/currency";

import { FaArrowLeft, FaCheck, FaTimesCircle, FaTimes } from "react-icons/fa";

import "./styles.css";

import "reactjs-popup/dist/index.css";

import Header from "../../components/Header";

import Popup from "reactjs-popup";

import { toast } from "react-toastify";

import api from "../../services/api";

import iconMoney from "../../assets/icon-money.png";

import iconCart from "../../assets/icon-cart.png";
import iconPix from "../../assets/icon-pix.png";

class ScreenCheckout extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let empresa = localStorage.getItem("@comer-aqui/empresa");

    empresa = JSON.parse(empresa);

    let taxaDeServico = empresa.vlTaxaentrega;

    let total = parseFloat(props.cart.totalprice) + parseFloat(taxaDeServico);

    let { agendamento } = props.location;

    this.state = {
      empresa: empresa,
      isMoney: false,
      isCart: false,
      isPix: false,
      isFatura: false,
      show: false,
      showInput: false,
      thing: 0,
      client: props.cart.client,
      taxaDeServico,
      total,
      agendamento,
      items: props.location.items,
      id_cupom: "",
      descricao: "",
      cod_cupom: "",
      ds_cupom: "",
      vl_cupom: 0,
      porc_cupom: "",
      tp_desconto: "",
      vl_min_pedido: "",
      dt_validade: "",
      discount: 0,
      discountpr: 0,
      show_cupom: false,
      show_remover_cupom: false,
      disable: false,
    };

    this.handleSubmitCupom = this.handleSubmitCupom.bind(this);
    this.handleChangeCodCupom = this.handleChangeCodCupom.bind(this);
  }

  changeScreenForStatus = async () => {
    try {
      if (!this.props.cart.totalprice || this.props.cart.totalprice <= 0) {
        toast.error(
          "Nenhum item encontrado, por favor adicione item ao seu carrinho!"
        );
        return;
      }

      let id_cliente = this.props.cart.client.id;

      let empresa = localStorage.getItem("@comer-aqui/empresaUrl");
      let objEmpresa = localStorage.getItem("@comer-aqui/empresa");

      let { taxaDeServico, vl_cupom, agendamento } = this.state;

      let thing = this.refs.thing ? String(this.refs.thing.value) : "";

      // if(this.state.tp_desconto == 1) {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico) - parseFloat(vl_cupom);
      // } else if(this.state.tp_desconto == 2) {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico) - parseFloat(this.state.total) * (parseFloat(this.state.vl_cupom / 100))
      //     console.log(`

      //     parseFloat(this.state.total) = ${parseFloat(this.state.total)} +
      //     parseFloat(taxaDeServico) = ${parseFloat(taxaDeServico)} -
      //     parseFloat(this.state.total) = ${parseFloat(this.state.total)} *
      //     (parseFloat(this.state.vl_cupom / 100)) = ${(parseFloat(this.state.vl_cupom / 100))}

      //     ${parseFloat(this.state.total)} + ${parseFloat(taxaDeServico)} - ${parseFloat(this.state.total)} *  ${(parseFloat(this.state.vl_cupom / 100))}

      //     total = ${this.props.cart.totalprice }

      //     `)

      //     // this.props.cart.totalprice = parseFloat(this.state.total) * (parseFloat(this.state.vl_cupom / 100))

      // } else {
      //     this.props.cart.totalprice = parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico)
      // }

      // console.log("antes =", this.props.cart.totalprice)
      // console.log("total =", this.state.total)
      // console.log("tp_desconto = ", this.state.tp_desconto)
      // console.log(this.state)

      // if (this.state.tp_desconto == 1) {
      //     this.props.cart.totalprice =
      //       parseFloat(this.props.cart.totalprice) +
      //       parseFloat(taxaDeServico) -
      //       parseFloat(vl_cupom);
      //   } else if (this.state.tp_desconto == 2) {
      //     // this.props.cart.totalprice =
      //     //   parseFloat(this.state.total) -
      //     //   parseFloat(this.state.total) * parseFloat(this.state.vl_cupom / 100)
      //     this.props.cart.totalprice = this.state.total

      //   } else {
      //     this.props.cart.totalprice =
      //       parseFloat(this.props.cart.totalprice) + parseFloat(taxaDeServico);
      //   }
      //   console.log("depois =", this.props.cart.totalprice)
      //   console.log("depois total =", this.state.total)

      this.props.cart.totalprice = this.state.total;

      if (this.props.cart.buscarOuEntregarAqui == "ENTREGAR_AQUI") {
        // ENTREGAR_AQUI = 4
        this.props.cart.typeorder = 4;
      } else {
        // VOU_BUSCAR = 2
        this.props.cart.typeorder = 2;
      }

      objEmpresa = JSON.parse(objEmpresa);

      let troco = 0.0;

      if (thing) {
        thing = thing.replace(".", "");
        thing = thing.replace(".", "");
        thing = thing.replace(".", "");
        thing = thing.replace(",", "");

        // Adrian - 22/03/2024
        // troco = (thing / 100).toFixed(2).replace(".", ",");
        // troco = troco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  //Modelo antigo

        troco = (thing / 100).toFixed(2).replace(".", ","); //Modelo atual
        troco = troco.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

      let forma = "DINHEIRO";

      let { isCart, isPix, isFatura } = this.state;

      if (isPix) {
        forma = "PIX";
      }

      if (isFatura) {
        forma = "FATURA";
      }

      if (isCart) {
        forma = "CARTAO";
      }

      /*  Gabriel - 16/04/2022 - A alteração no cart.js torna este laço desnecessário e
                                       corrige o erro nas exclusões dos itens do carrinho 
            let { items } = this.state
            // let { items } = this.props.cart

            let i = 1;
            for (let item of items) {
                item.id = i;
                //item.product.id = i;
                item.product.iditem = i;

                if (item.add) {
                    for (let add of item.add) {
                        add.iditem = i;
                    }
                }
                if (item.remove) {
                    for (let remove of item.remove) {
                        remove.iditem = i;
                    }
                }

                if (item.product.note) {
                    for (let note of item.product.note) {
                        if (note.observacao) {
                            for (let observacao of note.observacao) {
                                if (observacao.itens) {
                                    for (let item of observacao.itens) {
                                        item.iditem = i;
                                    }
                                }
                            }
                        }
                    }
                }

                /* if (item.product.items) {
                     for (let product_item of item.product.items) {
                         product_item.iditem = i;
                     }
                 }
 
                 item.product.sabores = item.product.items;
 
                 delete item.product.items; */

      /* i++;
            }

            this.props.cart.items = items;*/
      let params = {
        codempresa: objEmpresa.codEmpresa,
        ...this.props.cart,
        id_cliente,
        empresa,
        agendamento: agendamento.agendamento,
        dataagend: agendamento.data,
        horaagend: agendamento.hora,
        forma,
        troco,
        aditional: taxaDeServico,
        cod_cupom: "",
        discountpr: this.state.discountpr,
        discount: this.state.discount,
        token: localStorage.getItem("tokenValido"),
      };
      // localStorage.removeItem("tokenValido")
      // console.log("props =", this.state)

      // console.log(params)

      try {
        const response = await api.post(`/pedidos`, params, {
          headers: { "Content-Type": "application/json" },
        });
        localStorage.removeItem("tokenValido");
        this.props.checkOut();

        this.props.history.push("/status", {
          client: this.state.client,
          idpedido: response.data,
          total: this.state.total,
        });
      } catch (erro) {
        //
        console.log(erro);
        toast.error("Ocorreu erro na requisição, tente novamente.");
        this.setState({
          disable: false,
        });
      }

      //this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization'));

      //localStorage.removeItem('@comer-aqui/authorization');
      //localStorage.removeItem('@comer-aqui/numberOrder');
    } catch (error) {
      setTimeout(() => {
        this.setState({
          disable: false,
        });
      }, 3000);

      toast.error("Ocorreu erro na requisição");
      // toast.error(error.response.data)
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (
      !this.state.isMoney &&
      !this.state.isCart &&
      !this.state.isPix &&
      !this.state.isFatura
    ) {
      toast.error("Qual a forma de pagamento? Dinheiro ou Cartão?");
      return;
    }

    this.setState({ show: this.state.isMoney });

    if (this.state.isCart || this.state.isPix || this.state.isFatura) {
      this.changeScreenForStatus();
    }

    this.setState({
      disable: true,
    });
  };

  handleSelectTypePayment = (type) => {
    this.setState(
      {
        isMoney: type === "D",
        isCart: type === "C",
        isPix: type === "P",
        isFatura: type === "F",
      },
      () => {}
    );
  };

  handleThing = () => {
    let thing = this.refs.thing.value;

    thing = String(thing).replace(",", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");

    let formatPrice = (thing / 100).toFixed(2).replace(".", ",");
    thing = formatPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // console.log("formatPrice =", formatPrice)

    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(".", "");
    thing = String(thing).replace(",", ".");

    if (parseFloat(thing) < parseFloat(this.props.cart.totalprice)) {
      toast.error("Valor deve ser maior que o total!");
      return;
    }

    if (thing === "") {
      toast.error("Troco não informado!");
      return;
    }

    this.setState({ showInput: false, show: false });
    this.changeScreenForStatus();
  };

  async handleSubmitCupom(e) {
    e.preventDefault();

    try {
      let { empresa } = this.state;

      this.state.total = this.subtotal + this.taxaDeServico;

      if (!this.state.cod_cupom) {
        toast.error("Insira o código de cupom");
        return;
      }
      const response = await api.get(
        `/validacupom?cod_empresa=${empresa.codEmpresa}&cupom=${this.state.cod_cupom}`
      );

      this.setState({
        vl_cupom: response.data.vldesconto,
        tp_desconto: response.data.tpdesconto,
      });

      let totalMod = parseFloat(this.props.cart.subtotal);

      // console.log("1", totalMod)

      if (this.state.tp_desconto == 1) {
        totalMod = totalMod - this.state.vl_cupom;
        this.state.discount = this.state.vl_cupom;
        this.state.discountpr = 0;

        // console.log("2", totalMod)
      }
      if (this.state.tp_desconto == 2) {
        let porcen = totalMod * (this.state.vl_cupom / 100);
        totalMod = totalMod - porcen;
        this.state.discount = porcen;
        this.state.discountpr = this.state.vl_cupom;

        // console.log("3", totalMod)
        //     this.props.cart.totalprice = this.state.total
      }

      let newTotal = totalMod + parseFloat(this.state.taxaDeServico);

      // console.log("4", newTotal)

      this.setState({
        ds_cupom: this.state.cod_cupom,
        show_cupom: true,
        total: newTotal,
      });
    } catch (error) {
      toast.error("Cupom invlálido");
    }
  }

  handleChangeCodCupom(e) {
    this.setState({ cod_cupom: e.target.value });

    if (!this.state.cod_cupom) {
      this.setState({
        show_cupom: false,
        show_remover_cupom: true,
      });
    }
  }

  render() {
    if (this.props.cart.buscarOuEntregarAqui == "VOU_BUSCAR") {
      this.state.total -= this.state.taxaDeServico;
      this.state.taxaDeServico = 0;
    }

    let { total, taxaDeServico, vl_cupom, ds_cupom, tp_desconto } = this.state;

    const isNumeric = (value) => {
      var regra = /^[0-9]+$/;

      if (String(value).match(regra)) {
        return true;
      }

      return false;
    };

    const formatPrice = (value) => {
      if (value) {
        let val = (value / 100).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return 0;
    };

    const formatValueOnKeyUp = (value) => {
      value = value.replace(".", "");
      value = value.replace(".", "");
      value = value.replace(".", "");
      value = value.replace(",", "");
      if (!isNumeric(value)) {
        return 0;
      }
      return formatPrice(value);
    };

    const handleCupomKeyUp = (value) => {
      if (!this.state.cod_cupom) {
        this.setState({
          vl_cupom: 0,
          show_cupom: false,
          show_remover_cupom: false,
        });
        return value;
      }
    };

    return (
      <>
        <Header />
        <SweetAlert
          show={this.state.show}
          title="Troco"
          text="Precisa de troco?"
          confirmButtonText="Sim"
          cancelButtonText="Não"
          showCancelButton
          onConfirm={() => {
            this.setState({ show: false, showInput: true });
          }}
          onCancel={() => {
            this.setState({ show: false });
            this.changeScreenForStatus();
          }}
        />
        <Popup
          open={this.state.showInput}
          modal
          contentStyle={{
            maxWidth: "500px",
            width: "90%",
          }}
          onClose={() => {
            this.setState({
              disable: false,
            });
            this.setState({ showInput: false });
          }}
        >
          <div className="container-sm pt-5 pb-5 row m-0  ">
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 mb-2 text-right">
              <button
                className="btn btn-circle btn-danger"
                onClick={() => this.setState({ showInput: false })}
              >
                Fechar <FaTimesCircle />
              </button>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 mb-2">
              <h6>Troco para</h6>
              <input
                type="text"
                ref="thing"
                onKeyUp={(e) =>
                  (this.refs.thing.value = formatValueOnKeyUp(e.target.value))
                }
                placeholder="Troco para"
                className="form-control"
              />
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12">
              <button
                className="btn btn-block btn-success"
                onClick={this.handleThing.bind(this)}
              >
                Confirmar <FaCheck />
              </button>
            </div>
          </div>
        </Popup>
        <div id="container-checkout">
          <form className="row mt-3 mb-1" onSubmit={this.handleSubmitCupom}>
            <div className="col pr-0">
              <div style={{ position: "relative" }}>
                <input
                  type="text"
                  id="handle-cupom"
                  className="form-control w-100"
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    position: "relative",
                    paddingRight: "42px",
                  }}
                  value={this.state.cod_cupom}
                  onChange={this.handleChangeCodCupom}
                  onKeyUp={(e) =>
                    (this.value = handleCupomKeyUp(e.target.value))
                  }
                  placeholder="Código de cupom"
                />
                {this.state.show_remover_cupom && (
                  <span
                    className="btn btn-link text-danger btn-remover-cupom"
                    onClick={(e) => {
                      this.state.total =
                        parseFloat(this.props.cart.totalprice) +
                        parseFloat(this.state.taxaDeServico);

                      this.setState({
                        cod_cupom: "",
                        vl_cupom: 0,
                        show_remover_cupom: false,
                        show_cupom: false,
                      });
                    }}
                    style={{ position: "absolute", top: 0, right: 0 }}
                  >
                    <FaTimes />
                  </span>
                )}
              </div>
            </div>
            <div className="col-auto pl-0" style={{ marginBottom: 10 }}>
              <button
                type="submit"
                className="btn btn-success btn-cupom"
                style={{ boxShadow: "none!important" }}
              >
                Adicionar
              </button>
            </div>
          </form>

          <div className="container-info-checkout">
            <div>
              <span
                className="title-info-total"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 15,
                  fontWeight: 600,
                }}
              >
                Valor total dos itens:
                <span style={{ marginLeft: 10, fontWeight: 500 }}>
                  {Currency.formatterValue.format(this.props.cart.subtotal)}
                </span>
              </span>

              <span
                className="title-info-total "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 13,
                }}
              >
                {" "}
                Desconto:
                <span style={{ marginLeft: 10, fontWeight: 500 }}>
                  {this.state.show_cupom ? (
                    <>
                      {this.state.cod_cupom === this.state.ds_cupom && (
                        <>
                          {this.state.tp_desconto == 1 && (
                            <>
                              <div>
                                <b>
                                  {" "}
                                  {Currency.formatterValue.format(
                                    this.state.vl_cupom
                                  )}
                                </b>
                              </div>
                            </>
                          )}

                          {this.state.tp_desconto == 2 && (
                            <>
                              <div>
                                <b style={{ color: "#3e9a2c" }}>
                                  {" "}
                                  {this.state.vl_cupom}%{" "}
                                </b>{" "}
                                {Currency.formatterValue.format(
                                  parseFloat(this.props.cart.subtotal) *
                                    (this.state.vl_cupom / 100)
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    "R$ 0,00"
                  )}
                </span>
              </span>

              <span
                className="title-info-total"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: 13,
                }}
              >
                {" "}
                Taxa de serviço:
                <span style={{ marginLeft: 10, fontWeight: 500 }}>
                  {Currency.formatterValue.format(taxaDeServico)}
                </span>
              </span>

              <span className="title-info-total-final"> </span>

              <div className="d-flex align-items-center justify-content-between">
                <span>
                  <b>Total do pedido:</b>
                </span>
                <span className="label-totals" style={{ fontSize: 20 }}>
                  {" "}
                  {Currency.formatterValue.format(total)}
                </span>
              </div>
            </div>

            <fieldset className="mt-0">
              <h4 className="text-center mb-0">
                <b> Pagar na entrega </b>
              </h4>

              <ul className="items-payment" style={{ marginTop: 30 }}>
                <li
                  className={this.state.isMoney ? "selected" : ""}
                  onClick={() => this.handleSelectTypePayment("D")}
                  style={{
                    backgroundColor: this.state.isMoney ? "#000" : "#00000000",
                    color: this.state.isMoney ? "#fff" : "#000",
                    border: "solid 2px #000",
                    fontWeight: 600,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 110,
                    paddingTop: 20,
                    fontWeight: 700,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    class="bi bi-currency-dollar"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
                  </svg>

                  <span style={{ fontSize: 20 }}>Dinheiro</span>
                </li>

                <li
                  className={this.state.isCart ? "selected" : ""}
                  onClick={() => this.handleSelectTypePayment("C")}
                  style={{
                    backgroundColor: this.state.isCart
                      ? "#de2339"
                      : "#00000000",
                    color: this.state.isCart ? "#fff" : "#de2339",
                    border: "solid 2px #de2339",
                    fontWeight: 600,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 110,
                    paddingTop: 20,
                    fontWeight: 700,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    color={this.state.isCart ? "#fff" : "#de2339"}
                    fill="currentColor"
                    class="bi bi-credit-card-2-back-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1z" />
                  </svg>
                  <span style={{ fontSize: 20 }}>Cartão</span>
                </li>

                {this.state.empresa.tpUtilizapix === 1 && (
                  <li
                    className={this.state.isPix ? "selected" : ""}
                    onClick={() => this.handleSelectTypePayment("P")}
                    style={{
                      backgroundColor: this.state.isPix
                        ? "#00bdae"
                        : "#00000000",
                      color: this.state.isPix ? "#fff" : "#00bdae",
                      border: "solid 2px #00bdae",
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 110,
                      paddingTop: 20,
                      fontWeight: 700,
                    }}
                  >
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      fill={this.state.isPix ? "#fff" : "#00bdae"}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z"></path>
                        <path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z"></path>
                      </g>
                    </svg>
                    <span style={{ fontSize: 20 }}> Pix </span>
                  </li>
                )}

                {this.state.empresa.tpUtilizafatura === 1 && (
                  <li
                    className={this.state.isFatura ? "selected" : ""}
                    onClick={() => this.handleSelectTypePayment("F")}
                    style={{
                      backgroundColor: this.state.isFatura
                        ? "#eb9800"
                        : "#00000000",
                      color: this.state.isFatura ? "#fff" : "#eb9800",
                      border: "solid 2px #eb9800",
                      fontWeight: 600,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 110,
                      paddingTop: 20,
                      fontWeight: 700,
                    }}
                  >
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" style={{}} fill="currentColor" class="bi bi-calendar-week-fill" viewBox="0 0 16 16">
  <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M9.5 7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5m3 0h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5M2 10.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5"/>
</svg>
                    <span style={{ fontSize: 20 }}> Fatura </span>
                  </li>
                )}
              </ul>
            </fieldset>
          </div>

          <div style={{ margin: 200 }} />

          <div className="fixed-bottom" style={{ backgroundColor: "#fff" }}>
            <div
              style={{
                height: 1,
                backgroundColor: "#f1f1f1",
                margin: "0 20px 0 20px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                margin: 20,
              }}
            >
              <button
                className="btn btn-block btn-danger btn-go-back"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Voltar
              </button>

              <button
                className="btn btn-block btn-dark p-3"
                onClick={this.handleSubmit}
                disabled={this.state.disable}
              >
                Finalizar Pedido
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ cart: state.cart, client: state.client });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCheckout);
