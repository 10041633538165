import React, { Component, useState, useEffect } from "react";

import { connect } from "react-redux";

import "./styles.css";

import Header from "../../components/Header/index";

import ItemCart from "../../components/ItemCart/index";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { Creators as ProductCreator } from "../../store/ducks/product";

import Currency from "../../utils/currency";

import { FaPlus, FaCheck, FaTimesCircle, FaTimes } from "react-icons/fa";

import { toast } from "react-toastify";

import Popup from "reactjs-popup";
import { BsCameraFill } from "react-icons/bs";
import api from "../../services/api";
import SweetAlert from "sweetalert2-react";

import "sweetalert/dist/sweetalert.css";
import { QRScaner, Camera } from "react-qrcode-scan";
import QRReader from "../../components/QRCodeReader";

class Cart extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    let empresa = localStorage.getItem("@comer-aqui/empresa");

    if (!empresa) {
      return;
    }

    localStorage.setItem("typeorder", this.props.cart.typeorder);

    empresa = JSON.parse(empresa);

    let tipoPadrao = localStorage.getItem("@comer-aqui/typeOrder");

    if (this.props.cart.typeorder == 1) {
      empresa.vlTaxaentrega = 0;
    }

    let local = null;

    let taxaDeServico = empresa.vlTaxaentrega;

    this.state = {
      items: props.cart.items,
      showPopMesa: false,
      showPopQRCodeMesa: false,
      empresa,
      taxaDeServico,
      local,
      show: false,
      onStartCam: false,
      existInVerificaimpressora: false,
      disable: false,
      typeOrder: tipoPadrao,
    };
  }

  handleMoreItems = (e) => {
    this.props.removeProduct();

    e.preventDefault();

    if (this.props.cart.typeorder == 4) {
      let empresa = localStorage.getItem("@comer-aqui/empresaUrl");
      if (!empresa) {
        this.props.history.push("/home");
        return;
      }
      this.props.history.push(empresa);

      // this.props.history.push(`/?nomeEmpresa=` + 'gerence');
    } else if (this.props.cart.typeorder == 1) {
      // console.log('21 = /?id=' + localStorage.getItem('@comer-aqui/authorization'))
      //this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization'));
      this.props.history.push(
        "/" +
          localStorage.getItem("@comer-aqui/empresaUrl") +
          "/?id=" +
          localStorage.getItem("@comer-aqui/authorization")
      );
      // console.log('22 = /?id=' + localStorage.getItem('@comer-aqui/empresaUrl/authorization'))
    }
  };

  handleFinalizeOrder = async () => {
    try {
      if (!this.props.cart.totalprice > 0) {
        toast.error(
          "Nenhum item encontrado, por favor adicione item ao seu carrinho!"
        );
        return;
      }

      let { empresa } = this.state;

      // if (empresa.situacao == 0) {
      //     return;
      // }

      // Cesar - 20/07/2022
      if (localStorage.getItem("@comer-aqui/typeOrder") == "MESA") {
        if (empresa.tpSituacaoqrcode) {
        } else {
          if (empresa.situacao == 0) {
            return;
          }
        }
      } else {
        if (empresa.situacao == 0) {
          return;
        }
      }

      let { cart } = this.props;

      if (localStorage.getItem("@comer-aqui/typeOrder") == "MESA") {
      } else {
        if (parseFloat(cart.totalprice) < parseFloat(empresa.vlPedidominimo)) {
          toast.error(
            `Soma total dos itens no carrinho abaixo do valor mínimo de ${Currency.formatterValue.format(
              empresa.vlPedidominimo
            )} aceito!`
          );
          return;
        }
      }

      for (const item of cart.items) {
        if (item.borda) {
          const borda = {
            id: item.borda.idProduto,
            descricao: item.borda.dsProduto,
            valor: item.borda.vlPreco,
          };

          delete item["borda"];
          item.product.borda = borda;
        }
      }

      if (this.props.cart.typeorder == 4) {
        this.props.history.push("/user", { items: this.state.items });
      } else if (this.props.cart.typeorder == 1) {
        //( - #Gabriel
        let objEmpresa = localStorage.getItem("@comer-aqui/empresa");
        objEmpresa = JSON.parse(objEmpresa);
        let idEmpresa = objEmpresa.codEmpresa;

        let params = {
          token: localStorage.getItem("tokenValido"),
          local: parseInt(this.state.local),
          codempresa: idEmpresa,
          ...this.props.cart,
        }; //)

        // localStorage.removeItem("tokenValido")

        var newData = new Date();
        var horaPedido = newData.toString().substring(16, 21);

        localStorage.setItem("horapedido", horaPedido);

        try {
          await api.post(`/pedidos`, params, {
            //( - Gabriel - Trocado cart por params
            headers: {
              "Content-Type": "application/json",
            },
          });
        } catch (erro) {
          //
          console.log(erro);
          toast.error("Ocorreu erro na requisição, tente novamente.");
          this.setState({
            disable: false,
          });
        }

        // console.log(response)

        // console.log("teste = ", '/' + localStorage.getItem('@comer-aqui/empresaUrl') + '/?id=' + localStorage.getItem('@comer-aqui/authorization'))
        // this.props.history.push('/?id=' + localStorage.getItem('@comer-aqui/authorization') + '&nomeEmpresa=' + localStorage.getItem('@comer-aqui/nomeEmpresa'));

        this.props.history.push(
          "/" +
            localStorage.getItem("@comer-aqui/empresaUrl") +
            "/?id=" +
            localStorage.getItem("@comer-aqui/authorization")
        );

        // Cesar - 11/10/22
        localStorage.setItem(
          "@comer-aqui/msgToast",
          "Pedido finalizado com sucesso!"
        );

        //localStorage.removeItem('@comer-aqui/authorization');
        //localStorage.removeItem('@comer-aqui/numberOrder');

        // toast.error("Ocorreu erro na requisição");

        this.props.checkOut();
      }
    } catch (error) {
      // console.log(error)
    }
  };

  verifyUtilizeQrcode = () => {
    if (!this.props.cart.totalprice > 0) {
      toast.error(
        "Nenhum item encontrado, por favor adicione item ao seu carrinho!"
      );
      return;
    }
    let empresaString = localStorage.getItem("@comer-aqui/empresa");
    var empresa = JSON.parse(empresaString);
    var tpUtilizaqrcode = empresa.tpUtilizaqrcode;

    if (tpUtilizaqrcode == 0) {
      this.handleFinalizeOrder();
    } else if (tpUtilizaqrcode == 1) {
      if (localStorage.getItem("horapedido")) {
        var newData = new Date();
        var dataPed = localStorage.getItem("horapedido");
        var dataAtual = newData.toString().slice(16, 21);

        let [horaInicioHrs, horaInicioMin] = dataPed.split(":").map(Number);
        let [horaFimHrs, horaFimMin] = dataAtual.split(":").map(Number);

        let dataInicio = new Date();
        dataInicio.setHours(horaInicioHrs, horaInicioMin, 0, 0);

        let dataFim = new Date();
        dataFim.setHours(horaFimHrs, horaFimMin, 0, 0);

        let diferencaMs = dataFim - dataInicio;

        let diferencaHoras = Math.floor(diferencaMs / (1000 * 60 * 60));
        let diferencaMinutos = Math.floor(
          (diferencaMs % (1000 * 60 * 60)) / (1000 * 60)
        );

        console.log(
          `Diferença: ${diferencaHoras} horas e ${diferencaMinutos} minutos`
        );

        if (diferencaHoras < 2) {
          this.setState({ showPopMesa: true });
        } else {
          localStorage.removeItem("horapedido");
          this.handleFinalizeOrder();
        }
      } else {
        this.handleFinalizeOrder();
      }
      // this.handleFinalizeOrderByTable();
    } else if (tpUtilizaqrcode == 2) {
      // this.handleFinalizeOrderByTable();
      this.setState({ showPopQRCodeMesa: true });
      this.handleStartCam();
    }
  };

  verifyValueMesa = () => {
    this.setState({ showPopMesa: false });
    this.setState({ showPopQRCodeMesa: false });
    this.handleFinalizeOrder();
  };

  handleFinalizeOrderByTable = () => {
    if (this.props.cart.typeorder == 1) {
      if (
        this.props.cart.items.length > 0 &&
        this.state.existInVerificaimpressora
      ) {
        this.setState({ show: true });
      } else {
        this.setState({ show: false });
        if (this.state.typeOrder == "ENTREGA") {
          this.handleFinalizeOrder();
        } else {
          this.verifyUtilizeQrcode();
        }
      }
    } else {
      if (this.state.typeOrder == "ENTREGA") {
        this.handleFinalizeOrder();
      } else {
        this.verifyUtilizeQrcode();
      }
    }
    this.setState({
      disable: true,
    });
  };

  checkVerificaimpressora = async (item, e) => {
    let items = [];

    let checked = e.target.checked;

    //( #Gabriel - idImpressora não estava sendo enviado no post
    /* for (let itemCart of this.state.items) {
            let idImpressora = null;

            if (itemCart.idImpressora) {
                idImpressora = itemCart.idImpressora;
            }

            if (itemCart.id == item.id) {
                if (checked) {
                    idImpressora = item.inVerificaimpressora;
                } else {
                    idImpressora = null;
                }
            }
            items.push({ ...itemCart, idImpressora });
        } */

    for (let itemCart of this.props.cart.items) {
      if (itemCart.id == item.id) {
        if (checked) {
          itemCart.idImpressora = item.inVerificaimpressora;
        } else {
          itemCart.idImpressora = null;
        }
      }
    } //)

    this.setState({ items });
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    window.onpopstate = this.onBackButtonEvent;

    let existInVerificaimpressora = false;

    //(Gabriel - 24/06/2022
    let existProdutosConvencionais = false;

    for (let item of this.props.cart.items) {
      if (!item.inVerificaimpressora) {
        existProdutosConvencionais = true;
      }
    } //)

    // console.log("props", this.props);

    //Gabriel - 24/06/2022 - Adicionado if para validar se é mesa e se existem produtos que não são bebidas
    if (
      localStorage.getItem("@comer-aqui/typeOrder") == "MESA" &&
      existProdutosConvencionais
    ) {
      for (let item of this.props.cart.items) {
        if (item.inVerificaimpressora) {
          existInVerificaimpressora = true;
        }
      }
    }

    // console.log("typeOrder", localStorage.getItem('@comer-aqui/typeOrder'));
    // console.log("existInVerificaimpressora", existInVerificaimpressora);

    this.setState({ existInVerificaimpressora });
  }

  handleQRScan = (data) => {
    console.log("Valor do código QR:", data);
    this.setState({ local: data }); // Atualiza o estado do componente pai com o valor do código QR
    this.handleFinalizeOrder();
  };

  handleStartCam = () => {
    const timeoutId = setTimeout(() => {
      this.setState({ onStartCam: true });
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  render() {
    let { taxaDeServico } = this.state;

    let existInVerificaimpressora = false;

    for (let item of this.props.cart.items) {
      if (item.inVerificaimpressora) {
        existInVerificaimpressora = true;
      }
    }

    return (
      <div style={{ backgroundColor: "#fff", height: "100vh" }}>
        <Header />

        <Popup
          open={this.state.show}
          modal
          contentStyle={{
            maxWidth: "100%",
            width: "90%",
          }}
        >
          <div className="py-3 row m-0">
            <div className="col-12">
              <h6 className="m-0">
                Selecione o(s) produto(s) que deseja receber agora na mesa
              </h6>
            </div>
            <div className="col-md-12 p-0 mt-2 mb-3">
              <ul className="list-group">
                {this.props.cart.items.length > 0 &&
                existInVerificaimpressora ? (
                  this.props.cart.items.map(
                    (item) =>
                      item.inVerificaimpressora && (
                        <div key={`${item.id}-${item.product.id}`}>
                          <div className="row m-0">
                            <div className="col-md-12">
                              <label
                                className="d-flex align-items-center label-add-complement"
                                htmlFor={item.product.id}
                              >
                                <div className="check">
                                  <input
                                    className="form-check-input-product"
                                    type="checkbox"
                                    id={item.product.id}
                                    data-id={item.product.id}
                                    name={item.product.id}
                                    checked={item.product.idImpressora}
                                    onClick={(e) => {
                                      this.checkVerificaimpressora(item, e);
                                    }}
                                    style={{ marginTop: "2px" }}
                                  />
                                  <span
                                    className="ml-2"
                                    htmlFor={item.product.id}
                                  >
                                    {item.product.quantity > 0 && (
                                      <span className="item-quantity">
                                        {item.product.quantity}
                                      </span>
                                    )}
                                    {item.product.quantity > 0 && (
                                      <span> x </span>
                                    )}
                                    <span className="item-description">
                                      {item.product.dename}
                                    </span>
                                    {item.product.name}
                                  </span>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <span className="title-complement-not-found">
                    {" "}
                    Nenhum produto adicionado :({" "}
                  </span>
                )}
              </ul>
            </div>
            <div className="col-md-12 col-xl-12 col-sm-12 col-12 text-center">
              <button
                className="btn btn-circle btn-block btn-danger"
                onClick={() => this.setState({ show: false })}
              >
                CANCELAR <FaTimesCircle />
              </button>
              <button
                className="btn btn-block btn-success mt-2"
                onClick={() => this.handleFinalizeOrder(this)}
              >
                FINALIZAR PEDIDO <FaCheck />
              </button>
            </div>
          </div>
        </Popup>
        <div id="container-cart">
          <div className="order-title">
            <span className="title-order-preview" style={{color: "#333"}}>Resumo do pedido</span>
          </div>
          <div className="order-preview">
            <div
              className={`order-products ${
                !this.props.cart.items.length > 0 &&
                "d-flex align-items-center h-100"
              }`}
            >
              {this.props.cart.items.length > 0 ? (
                this.props.cart.items.map((item, key) => (
                  <ItemCart key={key} item={item} />
                ))
              ) : (
                <h6 className="text-center">
                  Nenhum item adicionado ao carrinho :(
                </h6>
              )}
            </div>
          </div>

          <div className="fixed-bottom action-bottom" height="auto">
            <div
              style={{
                height: 1,
                backgroundColor: "#f1f1f1",
                margin: "0 20px 0 20px",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                margin: 20,
              }}
            >

              <button style={{display: 'flex',fontSize: 20, width: "100%", alignItems: 'center', justifyContent: 'center', backgroundColor: "#0d6efd", border: "none", color: "#fff"}} onClick={this.handleMoreItems}>
                Escolher mais <FaPlus />
              </button>
            <div
              className="container order-totals"
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              {taxaDeServico > 0 && (
                <>
                  <span className="title-totals">Totais</span>
                  <div className="wrapp-service-value">
                    <span className="title-service-value">Taxa de serviço</span>
                    <span className="service-value">
                      {Currency.formatterValue.format(taxaDeServico)}*
                    </span>
                  </div>
                </>
              )}
              <div className="wrapp-amount">
                <span className="title-amount">Total</span>
                <span className="amount">
                  {Currency.formatterValue.format(
                    parseFloat(this.props.cart.totalprice) +
                      parseFloat(taxaDeServico)
                  )}
                </span>
              </div>
            </div>

            <div className="actions">
              <button
                style={{ backgroundColor: "#000" }}
                onClick={this.handleFinalizeOrderByTable}
              >
                Finalizar pedido
              </button>
            </div>
            </div>

          </div>

          {this.state.showPopMesa && (
            <Popup
              open={this.state.showPopMesa}
              modal
              contentStyle={{
                maxWidth: "500px",
                width: "90%",
              }}
              onClose={() => {
                this.setState({
                  disable: false,
                });
                this.setState({ showPopMesa: false });
              }}
            >
              <div
                className="container-sm pt-4 pb-4 row m-0 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  className=""
                  style={{
                    flex: 1,
                    width: "100%",
                    justifyContent: "end",
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-circle btn-danger"
                    onClick={() => {
                      this.setState({ showPopMesa: false });
                    }}
                  >
                    Fechar <FaTimesCircle />
                  </button>
                </div>
                <div
                  className=""
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h6>Em qual mesa você está?</h6>
                  <input
                    type="number"
                    ref="thing"
                    value={this.state.local}
                    placeholder="Número da mesa"
                    onChange={(e) => {
                      if (e.target.value == "") {
                        this.setState({ local: null });
                      } else {
                        this.setState({ local: e.target.value });
                      }
                    }}
                    className="form-control"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className=""
                  style={{
                    flex: 1,
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn  btn-block  btn-success"
                    onClick={this.verifyValueMesa}
                    disabled={this.state.local == null}
                  >
                    Confirmar <FaCheck />
                  </button>
                </div>
              </div>
            </Popup>
          )}

          {this.state.showPopQRCodeMesa && (
            <Popup
              open={this.state.showPopQRCodeMesa}
              modal
              contentStyle={{
                maxWidth: "500px",
                width: "90%",
                height: "420px",
              }}
              onClose={() => {
                this.setState({
                  disable: false,
                });
                this.setState({ showPopQRCodeMesa: false });
                this.setState({ onStartCam: false });
              }}
            >
              <div
                className="container-sm pt-4 pb-4 row m-0 "
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  className=""
                  style={{
                    flex: 1,
                    width: "100%",
                    justifyContent: "end",
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-circle btn-danger"
                    onClick={() => {
                      this.setState({ showPopQRCodeMesa: false });
                      this.setState({ onStartCam: false });
                    }}
                  >
                    Fechar <FaTimesCircle />
                  </button>
                </div>

                <div
                  className=""
                  style={{
                    flex: 1,
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "286px",
                    }}
                  >
                    {!this.state.onStartCam && (
                      <div class="skeleton">
                        <BsCameraFill></BsCameraFill>
                        <span style={{ fontSize: "20px" }}>
                          Carregando câmera...
                        </span>
                      </div>
                    )}
                    <div
                      style={{ display: this.state.onStartCam ? "" : "none" }}
                    >
                      <QRReader onQRScan={this.handleQRScan}></QRReader>
                    </div>
                  </div>
                </div>
              </div>
            </Popup>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ cart: state.cart });

const mapDispatchToProps = { ...CartCreator, ...ProductCreator };

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
