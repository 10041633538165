import React from 'react';

 import './styles.css';

import Currency from '../../utils/currency';

export default function ItemComplement(props) {
    
    return (
        <li className="list-group-item"  htmlFor={props.name} style={{margin: 0, padding: 0}}>
            <label className="label-add-complement" style={{display: 'flex', alignItems: 'center', margin: 0, padding: 15}}>
                <div  style={{display: 'flex', alignItems: 'center', margin: 0, padding: 0}} >
                    <input
                        className="custom-checkbox"
                        type="checkbox"
                        id={props.name}
                        name={props.name}
                        checked={props.checked}
                        onChange={(e) => { props.onChecked(e, props.price, props.id) }} 
                        
                        />
                    <label htmlFor={props.name}  style={{margin: 0, padding: 0}}>
                        {props.name}
                    </label>
                </div>
                <div className="price">
                    {props.price >= 0 &&
                        <span>{Currency.formatterValue.format(props.price)}</span>
                    }
                </div>
            </label>
        </li>
    );
}