import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import Currency from "../../utils/currency";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { FaArrowLeft, FaCheck } from "react-icons/fa";

import Header from "../../components/Header";

import iconComerAqui from "../../assets/image/icon.png";

import "./styles.css";
import { HeaderProduto } from "../../components/HeaderProduto";

class ScreenPizzaBorder extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);
    console.clear();

    if (!props.product.product) {
      this.props.history.push({ pathname: "/" });
      return;
    }

    const filtraBorda = props.product.product.attrs.tmPizza;

    // let bordas = props.product.products.filter(({ tpProduto }) => tpProduto == 'O');
    // props.product.product.attrs.tmPizza

    let { bordas } = props.location;

    bordas = bordas.filter((item) => item.tmPizza === filtraBorda);

    if (bordas.length === 0) {
      bordas = props.location.bordas;
    }

    // console.log(bordas)

    let total = 0;
    let maiorValor = 0;
    let { items } = props.location.pizza;

    // console.log("props", props.location.pizza)

    // console.log("tudo da pizza =",  props.product.product.attrs.tmPizza)

    for (let item of items) {
      total = parseFloat(total) + parseFloat(item.total);
      if (item.value > maiorValor) {
        maiorValor = item.value;
      }
    }

    this.state = {
      items,
      quantity_flavors: props.location.quantity_flavors,
      total: total / props.location.quantity_flavors,
      bordas,
    };
  }

  onError() {
    if (!this.state.errored) {
      this.setState({ errored: true });
    }
  }

  handleInput = (e, item) => {
    const { bordas } = this.state;

    for (const borda of bordas) {
      if (borda.idProduto === item.idProduto) {
        borda.checked = !borda.checked;
      } else {
        borda.checked = false;
      }
    }

    this.setState(bordas);
  };

  handleSubmit = () => {
    let borda = this.state.bordas.filter(({ checked }) => checked);
    borda = borda[0];

    // console.log(this.state.bordas.filter(({ checked }) => checked))
    // console.log("tudo da pizza =", this.state)
    // console.log("tudo da pizza =",  this.props.location.pizza)

    this.props.history.push({
      pathname: "/pizza-quantity",
      pizza: this.props.location.pizza,
      quantity_flavors: this.props.location.quantity_flavors,
      product: this.props.location.product,
      borda,
    });
  };

  render() {
    const { product } = this.props;

    const { errored, bordas } = this.state;

    return (
      <div style={{ backgroundColor: "#fff", height: "100vh" }}>
        {/* <Header /> */}
        <div>
          <HeaderProduto
            img={this.props.product.product.url}
            alt={this.props.product.product.alt}
            nome={this.props.product.product.name}
            preco={Currency.formatterValue.format(
              this.props.product.product.vlPreco ||
                this.props.product.product.promotion_price
            )}
            descricao={this.props.product.product.description}
          />

          <div style={{ margin: "30px 0 30px 20px" }}>
            <h5 className="m-0">Bordas</h5>
          </div>

          {bordas.map((item) => (
            <label
              htmlFor={item.idProduto}
              key={item.idProduto}
              className="w-100 bg-white m-0 py-2"
              style={{border: "solid 1px #f1f1f1"}}
            >
              <div className="container d-flex align-items-center">
                <input
                  type="checkbox"
                  id={item.idProduto}
                  onClick={(e) => this.handleInput(e, item)}
                  checked={item.checked}
                  style={{ width: 24, height: 24}}
                />
                <span className="ml-1">
                  <b>{item.dsProduto}</b>
                </span>
                <span className="ml-auto text-secondary">
                  <b>{Currency.formatterValue.format(item.vlPreco)}</b>
                </span>
              </div>
            </label>
          ))}
        </div>

        <div className="espacamento" />


        <div style={{marginTop: 100}}/>

        <div className="fixed-bottom">
          <div
            style={{
              height: 1,
              backgroundColor: "#f1f1f1",
              margin: "0 20px 0 20px",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              margin: 20,
            }}
          >
                   <button
              className="btn btn-danger btn-block btn-go-back"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Voltar
            </button>

            <button
              className="btn btn-primary btn-block p-3"
              onClick={this.handleSubmit}
            >
              Avançar
            </button>
     
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScreenPizzaBorder);
